import React, { useEffect, useMemo, useState, useContext, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import TemplateX from "../../../../components/templates/TemplateX";
import {
  _GetSetTers,
  activateContent,
  contentConvert,
  createClass,
  setPropInputs,
} from "../../../../tools/tpl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";

import "../../../../App.css";
import { MfctContext } from "../../../../context";

import UserPin from "../../../../components/UserPin";

import { captureContent } from "../../../../service/background";

import { data } from "../../../../service/data";
import useLongPress from "../../../../tools/useLongPress";

import UserThumb from "../../../../components/thumbs/UserThumb";
import ShowContent from "./ShowContent";
import ShowPalette from "./ShowPalette";
import { getActions, loadAction, loadActions, runAction, runActionSync } from "../../../../tools/actions";
import { addWorkingStone } from "../../../../service/milestone";
import { loadAppFonts } from "../../../../tools/fonts";
var userActives = [];

var _posBlocs = {};
function SetPage() {




  const { page_id, type, app_id, module_id } = useParams();

  const {
    project,

    authUser,

  } = useContext(MfctContext);

  const session_id = "S" + authUser.user_id;
  const randomColor = () => {
    // Générer trois composants de couleur RGB aléatoires
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    // Convertir les composants RGB en chaîne hexadécimale et les concaténer
    const couleurHex = `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

    return couleurHex;
  };
  const [isReady, setIsReady] = useState();

  const [startPos, setStartPos] = useState({ left: 20, top: 20 });
  const [actionSafe, setActionSafe] = useState();
  const [users, setUsers] = useState([
    {
      session_id,
      imageUrl: authUser.profile.imageUrl,
      bgColor: randomColor(),
    },
  ]);
  const [schemas,setSchemas]=useState([]);

  const [baseContent, setBaseContent] = useState({
    "App.Name": "...",
  });
  const [page, setPage] = useState();

  const [app, setApp] = useState();
  const [actions, setActions] = useState();
  const [explode, setExplode] = useState();

  const [contents, setContents] = useState([]);
  const setCompleteContents = setContents;
  const [templates, setTemplates] = useState();
  const [selectedBloc, setSelectedBloc] = useState();
  const [selected, setSelected] = useState();

  const [selectedTemplate, setSelectedTemplate] = useState();

  const [saveAll, setSaveAll] = useState({})
  const [allPropsFromTemplates,setAllPropsFromTemplates] = useState({})
  const [loading, setLoading] = useState(false);
  const [internalContent, setInternalContent] = useState({});
  const  [showData, setShowData] = useState()
  const  [showPalette, setShowPalette] = useState()
  const setInternal = (key, value) => {
    setInternalContent((internalContent) => ({
      ...internalContent,
      [key]: value,
    }));
  };
  const scaleMoy = 0.9;
  const scaleMin = 0.75;
  const scaleMax = 0.9;
  const [isEmptyPage, setIsEmptyPage] = useState();
  const [isMod, setisMod] = useState();

  useEffect(() => {



    const dataInit = async () => {
      await data.init(
        "page",
        page_id,
        true,
        project,
        { setApp, setPage, setTemplates, setCompleteContents, setUser,setSaveAll },
        users[0],
        true
      );

      
      // Recuperer la page *
      let _page = await data.getPage(page_id);

 var  _schemas = await data.getSchemas();
 setSchemas(_schemas)
  let _templates = await data.getTemplates();
    
var propsParams ={}
                     
      _page.slug.split("/").forEach(function (key) {
      if(!key.startsWith(":")) return
      key = key.replace(":","")
      if( key.split("_id").length===2 )
        {
          propsParams["param_"+ key] ={  name:"param_"+ key,schema_id: 732,from:"fromUrl" }
        }
        else 
        {
          propsParams["param_"+ key] ={  name:"param_"+ key,schema_id: 42,from:"fromUrl" }
        }
      

      
      })

_page.propsParams=propsParams





      var _isEmptyPage =
        !_page.content.header &&
        !_page.content.footer &&
        _page.content.body.length === 0;

      setIsEmptyPage(_isEmptyPage);
      if (_isEmptyPage) setTimeout(() => setisMod(_isEmptyPage), 1000);

      // Recuperer l'app
      let _app = await data.getApp(_page.app_id);
      document.title = "✎PGE " + _page.title  + (_app.name ? " -  " + _app.name :"");;
      // Recuperer les templates


var allProps = {}

      _templates.forEach(t=> {

        if(t.props_from_templates  )
          {
               for (let template_id of Object.keys(t.props_from_templates)){
                  allProps[template_id]= {...t.props_from_templates[template_id],...propsParams}
                 
               }
                 
          }
      }  )

      setAllPropsFromTemplates(allProps)
      // Recuperer les contents

      // Recuperer les schemas
      //let _schemas = await data.getSchemasByPage(_page)

 await  addWorkingStone( _page, "Mise en page","page" )   

    };

    dataInit();
      


    return async () => {
      await data.close();
    };
  }, []);


const cancelling =async ()=>{


  data.cancelPage(page)
 
   
   }
   


   useEffect(()=>{

    if(page?.app_id) loadAppFonts(page.app_id)
  
  
  },[page])



useEffect(()=>{


  var paramsBeforeClose = localStorage.getItem("page_params_"+ page_id);
  if(paramsBeforeClose){

    var { isMod,showData,bloc} = JSON.parse(paramsBeforeClose);
  
    if(isMod)  setTimeout(() => {
      setisMod(isMod);
  
      if(bloc){
 setTimeout(() => {
  var obj=document.getElementById(bloc);

  if(obj) obj.scrollIntoView({behavior:"smooth",block:"center",inline:"center" })
     
  if(showData)  setShowData(showData);
 }, 500);

        
      }


  
    }, 10); 

    
    localStorage.removeItem("page_params_"+ page_id);

  }

},[])



const LinkTo = (link)=>{

//window.alert(" e" +  JSON.stringify( { isMod,selected,showData, bloc :lastPosBlocs && Object.keys(lastPosBlocs).find(key=> lastPosBlocs[key].isNearest )   }))
localStorage.setItem("page_params_"+ page_id,
JSON.stringify( { isMod,selected,showData, bloc :lastPosBlocs && Object.keys(lastPosBlocs).find(key=> lastPosBlocs[key].isNearest )   }));
 
navigate(link);


}



const saving =async ()=>{
 setLoading(true);
   await data.saveAll()
       setLoading(false);
       setisMod(false);
       setTimeout(   captureScreen,100)
       setShowData()
       
     }
     

  useEffect(() => {
    if (!app || !page) return;
    setBaseContent({
      "TitrePage": page.title,
      "App.app_id": app.app_id,
      domain: app.domain,
      "App.Name": app.name,
      "App.Logo":
        process.env.REACT_APP_MFTC_SERVER_URL_FILES +
        `profiles/${app.domain}/user.png`,
      "App.Domain": app.domain,
      "App.Description": app.description,
      "App.primary_color": app.profile.primary_color,
      "App.secondary_color": app.profile.secondary_color,
      "App.login": `window.alert("Login!!")`,
      "App.logout": `window.alert("Logout")`,
      "App.goBack": `window.alert("navigate(-1)")`,
    });


    app.profile.appColor?.forEach((color,i)=> 
      {
    var index= i+1
        createClass(
          "bg-app-color-" + index,
          ` background-color :${color} ;  `
        );
        createClass(
          "border-app-color-"+index,
          ` border-color :${color} ;   `
        );
        createClass(
          "text-app-color-"+index,
          ` color :${color}  ;  `
        );


      }
    )

    createClass(
      "bg-primary_color",
      ` background-color :${app.profile.primary_color} ;  `
    );
    createClass(
      "border-primary_color",
      ` border-color :${app.profile.primary_color} ;   `
    );
    createClass(
      "text-primary_color",
      ` color :${app.profile.primary_color}  ;  `
    );
    createClass(
      "border-secondary_color",
      ` border-color :${app.profile.secondary_color} ;   `
    );
    createClass(
      "bg-secondary_color",
      ` background-color :${app.profile.secondary_color}  ;  `
    );
    createClass(
      "text-secondary_color",
      `color :${app.profile.secondary_color}  ;  `
    );
  }, [page, app]);

  const _setSelectedBloc = (selectedBloc) => {
    setSelectedBloc(selectedBloc);
  };

  const _setSelectedTemplate = (selectedTemplate) => {
    setSelectedTemplate(selectedTemplate);
  };
  const _setSelected = (selected) => {
    setSelected(selected);
  };
  const sortBody = (i, pos) => {
    var i_ = i + pos;
    if (i_ < 0 || i_ >= page.content.body.length) return;

    var body = ("body" in page.content && page.content.body) ?? [];

    body = body.map((c, j) => {
      if (j === i) return body[i_];
      if (j === i_) return body[i];
      return c;
    });
    data.setPage({ ...page, content: { ...page.content, body } });

    _setSelectedBloc("bloc" + i_ + "-edit");
    if (selectedTemplate) {
      console.log("selectedTemplate", selectedTemplate);
      _setSelectedTemplate(
        (selectedTemplate + "").replace(
          "bloc" + i + "-edit",
          "bloc" + i_ + "-edit"
        )
      );
    }

    setBlocVisible("bloc" + i_ + "-edit");
    setBlocVisible("bloc" + i_);
  };
  const setBlocVisible = (id, focus) => {
    setTimeout(() => {
      var obj = document.getElementById(id);
      //console.log("search for visibility " + id, obj);
      if (obj) {
        //console.log("setBlocVisible to", obj.id, obj);
        obj.scrollIntoView({
          block: id === "page-content" ? "start" : "center",
          inline: "page-content" ? "start" : "center",
          behavior: "smooth",
        });
        if (focus) obj.focus();
      }
    }, 500);
  };


const getInfos=(template,content_id)=>{


  var infos = { app_id:page?.app_id}

var dataSources= []

var content = contents?.find(c=>c.content_id +""===""+ content_id) 

if(content && allPropsFromTemplates && allPropsFromTemplates[template.template_id])
  {

  Object.keys(content.content).filter(n=>n.startsWith("_data_")).forEach(key=>{

 var propname = key.replace("_data_", "");
 console.log(key +propname );
var prop = allPropsFromTemplates[template.template_id].props[propname]
if(prop)
  {
     console.log(key );
dataSources.push({ prop,dataSource_id:  content.content[key].content_id,template_id:template.template_id,pageParams:{} })
  }
 })
}
//window.alert( JSON.stringify(dataSources))
  return {...infos,dataSources} 

}

  const GetSetTers = (template, getSelf, setSelf,content_id) => {
  

return _GetSetTers( template,getSelf,setSelf,content_id,contents, allPropsFromTemplates, schemas ,   getInfos(template,content_id) )

   
  };


  const selectTpl = (id, tpl, noEmit,force) => {
    // console.log("selectTpl to", id, tpl);

    if (isMod || force) return;

    setBlocVisible(id.split(".")[0] + "-edit");
    _setSelectedBloc(id.split(".")[0] + "-edit");
    setBlocVisible(id);

    if (tpl) {
      _setSelectedTemplate(tpl);
      if (tpl.schema_label) {
        setBlocVisible(tpl.schema_label + "-schema_label", true);
      }
    }

    _setSelected(id);
    //    console.log("userActives", userActives, users);
    if (userActives) {
      var _user = userActives.find((user) => user.session_id === session_id);
      if (_user && _user.selected !== id) {
        _user.selected = id;

        setUser(_user, null, true);
      }
    }

    if (!noEmit)
      data.emit({
        user: {
          selected: id,
          selectedBloc: id.split(".")[0] + "-edit",
          selectedTemplate: tpl,
        },
      }); //,
  };

  const setUser = (_user, quit, yesEmit) => {
    console.log("UserpINS Start", userActives, _user, session_id);
    var newusers = [];
    if (quit) {
      newusers = userActives.filter(
        (user, i) => user.session_id !== _user.session_id
      );
    } else {
      if (userActives.find((user) => user.session_id === _user.session_id))
        newusers = userActives.map((user, i) =>
          user.session_id === _user.session_id ? _user : user
        );
      else {
        newusers = [...userActives, _user];
        sendMsg({});
      }
      console.log("UserpINS Before", newusers);
    }
    userActives = newusers.map((user, i) => ({
      ...user,
      pos: newusers.filter(
        (user0, i0) => i0 < i && user.selected === user0.selected
      ).length,
    }));
    console.log("UserpINS ", userActives);

    setUsers(userActives);

    //if (session_id === _user.session_id && yesEmit) sendMsg({ user: _user });
  };

  const sendMsg = (props) => {
    var _user = userActives.find((user) => user.session_id === session_id);
    props = { user: _user, ...props, page_id };

    data.emit(props);
  };


const data_updatePage= async (_page)=>{


await data.updatePage(_page);

}

  useEffect(() => {

const startPage=async()=>{

  await  activateContent(page, baseContent, project, setInternal, setIsReady);
  
  if(actions)return
  
  var _actions =getActions(page,templates)
  setActions(_actions)

    await loadActions(_actions);
  
    var onStartActions = _actions.filter(action=>action.Event==="onStart");
    for( let startAction of onStartActions ){
      if( startAction.asynchrone) await runAction(null, startAction,internalContent,setInternal,true)
      else  runActionSync(null, startAction,internalContent,setInternal,true)
    }
  
  }
  
if(page && templates)  startPage( )



   
  }, [page,actions,templates]);





  const safeDisplay = (msg, callback) => {
    setActionSafe({ msg, callback });
  };
  const longPress = useLongPress(
    () => {
      if (isMod) {
        console.log("Press Lon but isMod");

        return;
      }
      setisMod(true);
      setTimeout(() => {
        //  setBlocVisible("bloc_0");
      }, 10);
    },
    () => {},
    { shouldPreventDefault: false, delay: 500 }
  );

  const convertContents = useMemo(() => {
    var ccv = contents.map((c) => contentConvert(c));
    console.log("CCV", ccv);
    return ccv;
  }, [contents]);

  const navigate = useNavigate();

  const captureScreen = () =>
    captureContent(
      "page-content",
      `apps/${app.domain}`,
      `page_${page_id}_thumb.png`,
      project
    );

  function dragStart(event) {
    // Stocke la position de départ lorsque le déplacement commence
    //startPos.clientX=  event.clientX -  startPos.left
    // startPos.clientY=  event.clientY -  startPos.top
    // setStartPos(s=>({...s,clientX:event.clientX -  s.left,clientY:event.clientY -  s.top}))
  }

  /*
const dragEnd=(event)=>{
var obj = event.target
console.log("dragging", obj.offsetTop,obj.offsetLeft )
obj.style.top = obj.offsetTop + "px"
obj.style.left = obj.offsetLeft + "px"
}
*/

  function dragging(event) {
    // Calculer la nouvelle position en fonction de la position de départ et du déplacement

    //  startPos.left = event.clientX -   startPos.clientX;
    //  startPos.top = event.clientY -   startPos.clientY;

    //setStartPos(s=>({...s,left:event.clientX -   startPos.clientX,top:event.clientY -   startPos.clientY }))
    console.log(startPos);
    // Appliquer la nouvelle position à l'élément
    //event.target.style.left =   startPos.left + 'px';
    //event.target.style.top =    startPos.top + 'px';

    //left : startPos.left+"px",top:startPos.top+"px"
  }

  const [position, setPosition] = useState({ x: 4, y: 50 });
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const height = document.getElementById("editor_cursor").clientHeight;
    const width = document.getElementById("editor_cursor").clientWidth;

    console.log(height, width);
    console.log(document.body.clientWidth, document.body.clientHeight);
    const offsetX = e.clientX - position.x;
    const offsetY = e.clientY - position.y;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    function handleMouseMove(e) {
      console.log(height, width);
      console.log(document.body.clientWidth, document.body.clientHeight);
      setPosition({
        x: Math.max(
          0,
          Math.min(e.clientX - offsetX, document.body.clientWidth - width)
        ),
        y: Math.max(
          0,
          Math.min(e.clientY - offsetY, document.body.clientHeight - height)
        ),
      });
    }

    function handleMouseUp() {
      setIsDragging(false);
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }
  };

  var PosBlocs;
  const [lastPosBlocs, setLastPosBlocs] = useState({});
  const [nearestBloc, setNearestBloc] = useState();
  const [changingBloc, setChangingBloc] = useState();

  useEffect(() => {
    if (!isMod) {
      setLastPosBlocs();
      PosBlocs = {};
      return;
    }
    var isScrolling;
    var _nearestBloc;
    var ynearestBloc;

    // Définissez un délai pour vérifier si le défilement a cessé

    const scrollFunc = function () {
      if (!isMod || showData) return;
      clearTimeout(isScrolling);

      var sameId;
      var blocs = document.querySelectorAll(".snap-align");
      var idNearest;
      ynearestBloc = 3000;

      var idPrevious;

      blocs.forEach((elt) => {
        var rect = elt.getBoundingClientRect();
        var yBloc = Math.abs(
          rect.top + rect.height * 0.5 - window.innerHeight * 0.5
        );

        if (yBloc < ynearestBloc) {
          if (idNearest) _posBlocs[idNearest].isNearest = false;
          idNearest = elt.id;

          ynearestBloc = yBloc;
        }
        _posBlocs[elt.id] = {
          margin: 0 * (50 - yBloc) * 0.3,
          isNearest: idNearest === elt.id,
          scale: Math.min(
            scaleMax,
            Math.max(scaleMin, scaleMin + 1 / (1 + Math.abs(yBloc / 50)))
          ),
          y: yBloc,
          height: rect.height,
        };

        if (idPrevious) {
          _posBlocs[idPrevious].Next = elt.id;
          _posBlocs[elt.id].Previous = idPrevious;
        }
        idPrevious = elt.id;
        //   elt.style.transform = ` scale(${  _posBlocs[elt.id] })`;
        //  elt.style.zIndex = 10;
      });

      PosBlocs = _posBlocs;
      if (!changingBloc) resizingBlocs(_posBlocs);
      if (
        !changingBloc &&
        idNearest &&
        (!_nearestBloc || _nearestBloc.id !== idNearest)
      ) {
        setLastPosBlocs(_posBlocs);
        _nearestBloc = document.getElementById(idNearest);
        setNearestBloc(_nearestBloc);
        if (_nearestBloc) {
          setTimeout(() => {
            resizingBlocs(_posBlocs);
          }, 10);
        }
        console.log("nearestBloc ", _posBlocs, idNearest, _nearestBloc);
      }
    };

    window.addEventListener("scroll", scrollFunc);
    scrollFunc();

    return () => {
      window.removeEventListener("scroll", scrollFunc);
    };
  }, [isMod, changingBloc,showData]);

 
  const resizingBlocs = (_posBlocs) => {
    Object.keys(_posBlocs).forEach((id) => {
      var pos = _posBlocs[id];

      var bloc = document.getElementById(id);
      if (bloc) {
        bloc.style.transform = "scale(" + pos.scale + ")";

        //if(  bloc.previousSibling)  bloc.previousSibling.style.marginRight = nearestBloc?.id === id ?  (-pos.margin)   +"vw":0
        //if(  bloc.nextSibling)  bloc.nextSibling.style.marginLeft = nearestBloc?.id === id ?  (-pos.margin)   +"vw":0
        bloc.parentNode.scrollLeft =
          0.5 * bloc.parentNode.scrollWidth - bloc.parentNode.clientWidth * 0.5;
      }
    });
  };

  useEffect(() => {
    if (!PosBlocs) return;
  }, [PosBlocs]);



  var startScrollLeft =  null
var  innerWidth =  window.innerWidth 
var timerChangeTemplate=null;
  const ScrollingTemplate = ({
    id,
    scale,
    template_id,
    candidate_id,
    palette,
    onChangeTemplate,
    valideTemplate,
    nSideTemplate,
    content_id,
    layout,
  }) => {
    console.log("Scrolling template lOADING...");
    var nearScale = 0.65;
    var maxScale = 0.9;
    var scrolling = true;
    var nTemplates = 2 * nSideTemplate + 1;
    var scrollLeft = window.innerWidth * nSideTemplate;

  


 if( innerWidth !==window.innerWidth  || !startScrollLeft){
    startScrollLeft =  -window.innerWidth *nSideTemplate
     innerWidth =  window.innerWidth 

  }
  
    var ts = templates.filter(
      (t) =>
        (t && t.layout === layout) || t.template_id + "" === "" + candidate_id
    );
    if(!template_id)ts.push({template_id: candidate_id , 
      title : "Nouveau Bloc !",
      owner_id : authUser.user_id,
      structure:{
      className:
        "w-screen p-8 bg-white text-xl font-bold text-zinc-400 rounded-3xl flex flex-col items-center justify-center border-dashed border-4 border-zinc-400  h-full " +
        (layout === "bloc" ? " grow " : ""),
      tag: "text",
      text: id + " Vide",
      children: [],
    }     })
    var _index =
      ts.findIndex((t) => t.template_id + "" === "" + candidate_id) ?? 0;
    var _infos = { startCursor: 0, index: _index, tpls: [] };

    const populateTemplates = (infos, set) => {
      var tpls = [];
      for (
        var i = infos.index - nSideTemplate;
        i <= infos.index + nSideTemplate;
        i++
      ) {
        tpls.push(ts[(i + nSideTemplate * ts.length) % ts.length]);
      }
      var _infos = {
        startCursor: 0,
        startScrollLeft : infos?.startScrollLeft ?? startScrollLeft,
        index: _index,
        tpls,
        template_id:candidate_id,
        templates,
      };
      if (set) setInfos(_infos);
      console.log("populate infos", _infos);
      return _infos;
    };
const  setCursorTemplate= (direction,newStartCursor,nearestIndex,nearest_template_id)=>{
  
 if(!scrolling) return

  console.log("newTpls");
  var tpls = infos.tpls;
  var startCursor =infos.startCursor;
  var index =infos.index 
while(startCursor!==newStartCursor){
console.log("move cursor ",{ direction,index,startCursor,newStartCursor })
if( direction===1)
  {
    tpls[startCursor ] = ts[(index + nSideTemplate +1 + 2*ts.length) % ts.length];
  }
  else
  {
    var endCursor = (startCursor -1 +nTemplates)% nTemplates 
    tpls[endCursor ] = ts[(index - nSideTemplate -1 + 2*ts.length) % ts.length];
  }
  startCursor=   (startCursor + direction +nTemplates)% nTemplates
  
index=   (index + direction + 2*ts.length)% ts.length
startScrollLeft += direction*window.innerWidth;
console.log("new cursor ",{ direction,index,startCursor,newStartCursor })
}

  //on decharge le template startCursor
  var newInfos={
    startCursor,

    index,
    tpls,
    startScrollLeft,
  }
  console.log({"new Position" :true, template_id: ts[index].template_id, newInfos,startScrollLeft,scrollLeft});
  setInfos(newInfos);

 clearTimeout(timerChangeTemplate)
 timerChangeTemplate = setTimeout( ()=>{
  scrolling = false;
  timerChangeTemplate=null
  onChangeTemplate(
    ts[index].template_id
  );
 },400  )
 


}    
    const [infos, setInfos] = useState(populateTemplates(_infos));



    const moveTemplates = (_scrollLeft, scrollingObj) => {
      var d = _scrollLeft-scrollLeft;
      var direction = Math.sign(d)
      scrollLeft = _scrollLeft;
      startScrollLeft-=d
    //  console.log({startScrollLeft,scrollLeft});
      var nearest_template_id
     var nearestIndex
     var distanceNearest=3000
      for (var i = 0; i < infos.tpls.length; i++) {
        var tpl = infos.tpls[i];
        var pos =
          (i - infos.startCursor + infos.tpls.length) % infos.tpls.length;
        var obj = document.getElementById(
          "container_" + id + "_" + tpl.template_id
        );
        if (!obj) continue;
        var distance =
          (1.0 * Math.abs(window.innerWidth * pos + startScrollLeft)) /
          window.innerWidth;
        //  console.log("distance",{ i, distance,pos,startCursor: infos.startCursor })
if( distance<distanceNearest){

      nearestIndex =i
      distanceNearest=distance
      nearest_template_id=tpl.template_id;
    }
        var Rscale = Math.min(maxScale, 1.0 / (1 + distance));
        var left = nearScale * (window.innerWidth * pos + startScrollLeft);

       /* console.log(
          "i : " + i,
          "pos " + pos,
          "scale :" + Rscale,
          "distance " + distance
        );
        */
        obj.style.left = left + "px";
        obj.style.opacity = 1;
        obj.style.zIndex = Math.round(30 + Rscale * 100) + "";
        obj.style.transform = `scale(${Rscale})`;
    //    console.log(obj.style.left, "container_" + id + "_" + tpl.template_id);
      }

    
      var newStartCursor = (nearestIndex- nSideTemplate+ infos.tpls.length) % infos.tpls.length;
    
      if( newStartCursor!==infos.startCursor ){

console.log("newstart cursor",{nearestIndex, newStartCursor,actualCursor :infos.startCursor})

              setCursorTemplate(direction,newStartCursor,nearestIndex,nearest_template_id)     
      }
      
      if (scrollLeft <= (nSideTemplate - 1) * window.innerWidth) {
        scrollLeft = nSideTemplate * window.innerWidth;
        scrollingObj.scrollLeft = scrollLeft;

      } else if (scrollLeft >= (nSideTemplate + 1) * window.innerWidth) {
        scrollLeft = nSideTemplate * window.innerWidth;
        scrollingObj.scrollLeft = scrollLeft;

      }
 
    };


const moveScroll=(d)=>{

  var parentObj = document.getElementById("fake_scroll_" + id);
  if (parentObj) parentObj.scrollLeft+=d
  moveTemplates( parentObj.scrollLeft,parentObj)
}

    useEffect(() => {
      const onscroll = (e) => {
        if (!scrolling) {
          console.log("onscroll NOOOOOOOOO");
          return;
        }
       // console.log("onscroll AAAAHHHH");
        moveTemplates(e.target.scrollLeft, e.target);
      };

      var parentObj = document.getElementById("fake_scroll_" + id);
      if (parentObj) {
if(  parentObj.scrollLeft <= window.innerWidth * (nSideTemplate-1)  || parentObj.scrollLeft <= window.innerWidth * (nSideTemplate+1))  
 parentObj.scrollLeft = window.innerWidth * nSideTemplate;
 if(!scrolling) {
  console.log("startScrollLeft",{startScrollLeft ,infos_startScrollLeft:infos.startScrollLeft})
  //startScrollLeft = infos.startScrollLeft;      

 }
 scrolling = true;

        console.log("add onscroll");
    
        parentObj.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
        moveTemplates(parentObj.scrollLeft, parentObj);
        parentObj.addEventListener("scroll", onscroll);
      }
      return () => {
        console.log("remove onscroll");
        if (parentObj) parentObj.removeEventListener("scroll", onscroll);
      };
    }, [infos]);

   

    var tsIndex= (infos.startCursor + nSideTemplate+infos.tpls.length )% infos.tpls.length
    return (
      <>
        <div  key={"controller0_" + id} 
          className="w-full absolute top-0 left-0 safearea text-white text-xs   p-2 flex flex-col items-center justify-center  bg-black bg-opacity-40"
          style={{ zIndex: "30000000" }}
        >
          <div>
            {" "}
            ({infos.startCursor} - {infos.index} / {ts.length}){" "}
        
          </div>
          <div  key={"controller_" + id} className=" flex  items-center justify-center" 
                 style={{
                 
                  zindex:"1010"
                }}
          >
            {" "}
            {Array(ts.length)
              .fill(3)
              .map((_, index) => (
                <div
                  key={index}
                  className={
                    "rounded-full bg-white transition-all  " +
                    (index === infos.index
                      ? " h-2 w-2 opacity-100 "
                      : " h-1 w-1 opacity-50 ")
                  }
                  style={{
                    margin: "1px",
                    zindex:"100"
                  }}
              onClick={()=>{  onChangeTemplate(  ts[index].template_id )}}  ></div>
              ))}
          </div>
          <div> </div>
        </div>
        <div
        key={"fake_scroll_" + id}
          id={"fake_scroll_" + id}
          className=" w-screen  relative  transition-all duration-500 overflow-auto  container_template  "
        >
          <div
          onClick={valideTemplate}
            style={{
              display: "flex",
              flex: "none",
              height: "100vh",

              zIndex: "1000",
              backgroundColor: "transparent",
              width: (2 * nSideTemplate + 1) * 100 + "vw",
            }}
          ></div>

        </div>
        <FontAwesomeIcon
                icon={fa.faChevronLeft}
                className={
                  "appbutton text-white bg-black bg-opacity-30  z-20 border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-6 w-6   -mt-3  mx-2 absolute  left-0"
                }
                style={{
       top: "50vh",
                  zIndex: "1002",
                }}
                onClick={() => moveScroll(-window.innerWidth)
                
                }
             
              />
                                      <FontAwesomeIcon
                icon={fa.faChevronRight}
                className={
                  "appbutton text-white bg-black bg-opacity-30  z-20 border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-6 w-6  -mt-3 mx-2 absolute right-0"
                }
                style={{
                  top: "50vh",
                  zIndex: "1002",
                }}
                onClick={() => moveScroll(window.innerWidth)
                 
                }
             
              /> {infos.tpls.map((template, i) => {
          var distance =
            Math.abs(
              window.innerWidth *
                ((i - infos.startCursor + infos.tpls.length) %
                  infos.tpls.length) -
                (infos.startScrollLeft ?? startScrollLeft)
            ) / window.innerWidth;

          var Rscale = Math.min(maxScale, 1.0 / (1 + distance));
          var left =
            nearScale *
            (window.innerWidth *
              ((i - infos.startCursor + infos.tpls.length) %
                infos.tpls.length) +
              (infos.startScrollLeft ?? startScrollLeft));

          var zIndex = Math.round(30 + Rscale * 100) + "";
          return (
            <div
              key={id + "_" +template.template_id}
              id={"container_" + id + "_" + template.template_id}
              className={
                "    w-screen absolute flex flex-col items-center justify-center "
              }
              style={{
                top: "10vh",
                height: "80%",
                overflow: "hidden",
                transform: `scale(${Rscale})`,
                left: left + "px",
                zIndex,
                opacity: 0,
                flex: "none",
                //   transform : isMod ? "scale(" +scaling(id)+ ")" : "scale(1)" ,
              }}
            >
              {" "}
              <div key={"__TemplateX_" + id + "_" + template.template_id}
                className={
                  "    w-screen   overflow-hidden  rounded-3xl bg-white border-transparent transition-all " + (   tsIndex=== i  ? " shadow-xl  border-dashed  ":"")
                }
                style={{
                  transform: "scale(" + scale + ")",
                  
                  zindex: 30,
                }}
              >
                <TemplateX
                  key={"TemplateX_" + id + "_" + template.template_id}
                  label={id}
                  isFirst={template.title}
                  palette={palette}
                  template={
                    templates.find(
                      (t) => t.template_id + "" === "" + template.template_id
                    )?.structure ??
                    (isEmptyPage || isMod
                      ? {
                          className:
                            "w-screen p-8 bg-white text-xl font-bold text-zinc-400 rounded-3xl flex flex-col items-center justify-center border-dashed border-4 border-zinc-400  h-full " +
                            (layout === "bloc" ? " grow " : ""),
                          tag: "text",
                          text: id + " Vide",
                          children: [],
                        }
                      : null)
                  }
                  navigate={navigate} 
                  content={{
                    ...baseContent,
                    ...(convertContents.find(
                      (c) => c.content_id + "" === content_id + ""
                    ) ?? {}),

                    ...internalContent,
                  }}
                  template_id={template.template_id}
                  templates={templates}
                  selectTpl={() => {
                  
                  }}
                  id={id}
                  selected={selected}
                  safeDisplay={safeDisplay}
                />
             
              </div>
              <div className={" text-white p-2 w-9/12 transition-all flex  justify-between  "  +(tsIndex ===i ? " opacity-100 " : " opacity-0")   }>
   <div>{ template.title     }</div>
  <UserThumb   user_id={template.owner_id}   />



         </div>
            </div>
          );
        })}
      </>
    );
  };

  ///////
  const TemplateSwipe = React.memo(
    ({
      id,
      label,
      layout,
      template_id,
      content_id,
      palette,
      onChangeContent,
      onChangeTemplate,
      onChangePalette,
      onAddBloc,
      nearestBloc,
      onDelete,
    
    }) => {
      // console.log(id,PosBlocs)

      var pos = (lastPosBlocs && lastPosBlocs["container_" + id]) ?? {
        scale: 1,
      };

      var [candidate_template_id, setCandidate_template_id] =
        useState(template_id);
    
      var isNearest = nearestBloc?.id === "container_" + id;

      var isNextNearest =
        lastPosBlocs &&
        lastPosBlocs["container_" + id]?.Next === nearestBloc?.id;
      var scale = (lastPosBlocs && lastPosBlocs["container_" + id]?.scale) ?? 1;
      var margin =
        (lastPosBlocs && lastPosBlocs["container_" + id]?.margin) ?? 0;

      var templatesToScroll = [template_id];

      return (
       <> <div
          key={"TemplateSwipe_" + id}
          id={"TemplateSwipe_" + id}
          className={
            " flex    flex-col w-full items-center xgrow relative    " +
            (isMod ? " " : " ")
          }
          style={{
            marginTop: isMod ? (id === "header" ? "50vh" : "-0%") : "0",
            marginBottom: isMod ? (id === "footer" ? "50vh" : "-0%") : "0",
            //  height:selectedBloc ===id+"-edit" && isMod ? "100vh":"" ,
          }}
        >
 
          {(isEmptyPage ||
            isMod ||
            templates.find((t) => t.template_id + "" === "" + template_id)) && (
            <div
              id={"container_scroll_" + id}
              className={
                "w-screen  relative xgrow transition-all duration-500   overflow-hidden container_scroll_" +
                id +
                (isNearest ? " bg-black bg-opacity-0 " : "")
              }
              style={
                {
                  //   width: selectedBloc === id + "-edit" && isMod ? " 200vw" : "100%",
                  //          transform : isMod ? "scale("+  scale   +")" :"scale(1)"
                  // position: selectedBloc ===id+"-edit" && isMod ? " absolute" : "inherit",
                  // top:selectedBloc ===id + "-edit" && isMod ?  "0px":"" ,
                  //left:selectedBloc ===id+"-edit" && isMod ? "-50vw":"" ,
                }
              }
            >
              { changingBloc === id ? (
                <ScrollingTemplate
                  id={id}
                  scale={scale}
                  template_id={template_id}
                  onChangeTemplate={setCandidate_template_id}
                  nSideTemplate={3}
                  palette={palette}
                  valideTemplate={() => {
                    onChangeTemplate(candidate_template_id);
                    setChangingBloc();
                    startScrollLeft=null;
                  }}
                   candidate_id={candidate_template_id ?? template_id}
                  content_id={content_id}
                  layout={layout}
                />
              ) : (
                <div
                  id={"container_" + id}
                  className={
                    "   sliding   snap-align   w-screen  bg-white  overflow-clip " +
                    (isMod
                      ? "  rounded-3xl "
                      : " transition-all duration-500  rounded-none  ")
                  }
                  style={{
                    transform: isMod ? "scale(" + scale + ")" : "scale(1)",

                    zindex: 30,
                    marginRight: margin + "vw",
                    marginLeft: margin + "vw",
                    flex: "none",
                    //   transform : isMod ? "scale(" +scaling(id)+ ")" : "scale(1)" ,
                  }}
                >
                  <TemplateX
                    key={"TemplateX_" + id}
                    label={label ?? id}
                    palette={palette}
                    template={
                      templates.find(
                        (t) => t.template_id + "" === "" + template_id
                      )?.structure ??
                      (isEmptyPage || isMod
                        ? {
                            className:
                              "w-screen p-8 bg-white text-xl font-bold text-zinc-400 rounded-3xl flex flex-col items-center justify-center border-dashed border-4 border-zinc-400  h-full " +
                              (layout === "bloc" ? " grow " : ""),
                            tag: "text",
                            text: id + " Vide",
                            children: [],
                          }
                        : null)
                    }
                    navigate={navigate} 
                    content={{
                      ...baseContent,
                      ...(convertContents.find(
                        (c) => c.content_id + "" === content_id + ""
                      ) ?? {}),

                      ...internalContent,
                      ...( template_id ? setPropInputs(  templates.find(
                        (t) => t.template_id + "" === "" + template_id
                      ),schemas,templates):{}),
                      ...GetSetTers (templates.find(
                        (t) => t.template_id + "" === "" + template_id
                      ), null, null,content_id),
                      GetSetTers: (template,getSelf,setSelf)=>GetSetTers(template,getSelf,setSelf, content_id)
                    }}
                    template_id={template_id}
                    templates={templates}
                    selectTpl={selectTpl}
                    id={id}
                    selected={selected}
                    safeDisplay={safeDisplay}
                  />
                </div>
              )}
            </div>
          )}
           { isMod && isNearest && onDelete && !changingBloc && (
            <div className="absolute left-6 top-0  flex flex-col"  style={{zIndex:1000000}} >
            <FontAwesomeIcon
                   icon={fa.faPalette }
                 
                   className={
                     "appbutton text-yellow-400   border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 "+ (  showPalette===id ?"  bg-white bg-opacity-60" :" bg-black bg-opacity-30" )
                   }
                   style={{zIndex:10000000}}
                   onClick={() => setShowPalette( showPalette=>  showPalette===id ? null : id )}
                 />{ showPalette===id &&  <div className=" ">
                              <ShowPalette   palette={ palette } 
                              id={id}
                                        onChangePalette={  onChangePalette }
                                        template={  templates.find(
                                         (t) => t.template_id + "" === "" + template_id
                                       )}
                                       templates={templates}
                                       setShow={()=>setShowPalette()}
                                        app={app}
     
                                    
                                        /> </div>}
</div>)
           }
          { isMod && isNearest && onDelete && !changingBloc && (
            <div className="absolute right-6 top-10  flex flex-col">

           
              <FontAwesomeIcon
              icon={fa.faEdit}
              onClick={()=>LinkTo(`/template/${template_id}`)}
              className={
                "appbutton text-blue-600  bg-black bg-opacity-30  border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 "
              }
              style={{zIndex:10000000}}

            />
            
            <FontAwesomeIcon
              icon={fa.faTrash}
          
              className={
                "appbutton text-red-600  bg-black bg-opacity-30  border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 "
              }
              style={{zIndex:10000000}}
              onClick={onDelete}
              onMouseOver={()=>{ var obj =document.getElementById("container_"+id)
              if(obj){
obj.style.opacity="0.5" 
//obj.style.border="5px solid red" 
}
              }}
              onMouseOut={()=>{ var obj =document.getElementById("container_"+id)
              if(obj){
obj.style.opacity=null 
obj.style.border=null
}}}
            /></div>
          )}
          {isMod &&  (showData===id  || changingBloc===id || ( !changingBloc && isNearest)) && (
            <div className="w-full h-20 -mt-20 sticky bottom-0 " style={{ zIndex: "1000" }}>
              
              {changingBloc === id && candidate_template_id !== template_id && (
             <FontAwesomeIcon
                  icon={fa.faCheck}
                  className={
                    "appbutton text-white bg-black bg-opacity-30  z-20 border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 "
                  }
                  onClick={() => {
                    onChangeTemplate(candidate_template_id);
                    setChangingBloc();
                    startScrollLeft=null;
                  }}
                ></FontAwesomeIcon>
              )}
              <FontAwesomeIcon
                icon={changingBloc !== id ? fa.faLeftRight : fa.faClose}
                className={
                  "appbutton text-white bg-black bg-opacity-30  z-20 border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 "
                }
                onClick={() =>
                 { setChangingBloc((_id) => (_id === id ? null : id))
                  startScrollLeft=null;
                 }
                }
              >
                {" "}
              </FontAwesomeIcon>
              {  changingBloc !== id  &&  <FontAwesomeIcon
                    icon={fa.faDatabase}
                    className={
                      "appbutton text-white bg-black bg-opacity-30  z-20 border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 "
                    }
                    onClick={() => setShowData(showData=>showData===id ? null : id )}
                  ></FontAwesomeIcon> }
                                            
            </div>
          )}{ showData===id &&  <div className=" top-4 right-4 w-10/12 " style={{zindex:1000000}}>
                         <ShowContent   
                         allPropsFromTemplates={allPropsFromTemplates}
                         
                         
                         content={ content_id ? contents.find(
                                     (c) => c.content_id + "" === content_id + ""
                                   ) :null}
                                   onChangeContent={  onChangeContent }
                                   template={  templates.find(
                                    (t) => t.template_id + "" === "" + template_id
                                  )}
                                  templates={templates}
                                   schemas={schemas}
                                   setShow={()=>setShowData()}
                                   app_id={app.app_id}

                                   infos={{}}
                                   page={page}
                                   /> </div>}
 
          {isMod && (
            <div
              className={
                "   scale-100 z-20 w-full flex flex-row items-center justify-between  transition-all duration-200  sticky top-8 bottom-8" +
                (isMod ? "p-1" : "p-0") +
                (isMod && (isNearest || isNextNearest)
                  ? " bg-black bg-opacity-0  "
                  : "  ")
              }
            >
              {isNearest ? (
                <div className="text-white p-2 flex   items-center w-20">{id}</div>
              ) : (
                <div className="text-white p-2 flex   items-center w-20"></div>
              )}

              {onAddBloc ? (
                <div
                  className={
                    "text-white  flex items-center " +
                    (!changingBloc && isMod && (isNearest || isNextNearest)
                      ? " opacity-1 "
                      : " opacity-0  select-none  ")
                  }
                >
                
                  <FontAwesomeIcon
                    icon={fa.faPlus}
                    className={
                      "appbutton text-white bg-black bg-opacity-30  z-20 border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 "
                
                    }
                    onClick={isNearest || isNextNearest ? onAddBloc : () => {}} 
                  ></FontAwesomeIcon>
                </div>
              ) : (
                <div ></div>
              )}
              <div className="text-white p-2 flex   items-center w-20">&nbsp;</div>
            </div>
          )}
  
        </div>
                    
                                   </>
      );
    }
  );

  if (!isReady) return <>cdzdcdszv</>;
  return (
    <div className="App  text-zinc-600 text-sm relative">
     
        <div
          className="safearea-bottom fixed flex flex-col items-center justify-between bottom-4 right-4  p-1 rounded-full"
          style={{ zIndex: 60 }}
         
        >
 {isMod && !changingBloc && (
  <>
{Object.values(saveAll).length > 0 &&
<><FontAwesomeIcon
                    icon={fa.faChevronLeft}
                    className={
                      "appbutton text-red-600 bg-black bg-opacity-30  z-20 border border-red-600 border-opacity-90 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 "
                    }
                    onClick={cancelling}
                  ></FontAwesomeIcon>
                  <FontAwesomeIcon
                    icon={fa.faAngleDoubleLeft }
                    className={
                      "appbutton text-red-600 bg-black bg-opacity-30  z-20 border border-red-600 border-opacity-90 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 mb-4 "
                    }
                    onClick={cancelling}
                  ></FontAwesomeIcon>
<FontAwesomeIcon
                    icon={fa.faCheck}
                    className={
                      "appbutton text-green-600 bg-black bg-opacity-30  z-20 border border-green-600 border-opacity-90 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 "
                    }
                    onClick={saving}
                  ></FontAwesomeIcon>
</>}
            <FontAwesomeIcon
                    icon={fa.faClose}
                    className={
                      "appbutton text-white bg-black bg-opacity-30  z-20 border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 "
                    }
                    onClick={() => setisMod(!isMod)}
                  ></FontAwesomeIcon>
  </> )} 

  { !isMod  &&  <FontAwesomeIcon
                    icon={fa.faPen}
                    className={
                      "appbutton text-white bg-black bg-opacity-30  z-20 border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-6 w-6  m-1 mx-2 "
                    }
                    onClick={() => setisMod(true)}
                  ></FontAwesomeIcon> }
  
  </div>
      
      {!isMod && (
        <div
          id="editor_cursor"
          className="fixed flex flex-col items-center justify-between  bg-white p-1 rounded-full"
          style={{
            zIndex: 60,
            left: position.x + "px",
            top: position.y + "px",
            position: "fixed",
          }}
          onMouseDown={handleMouseDown}
          draggable="false"
          onDragStart={dragStart}
          onDrag={dragging}
        >
          <div className="flex">
            <Link
              to={
                module_id
                  ? `/modules/${module_id}/Pages`
                  : `/apps/${app_id}/Pages`
              }
            >
              <FontAwesomeIcon
                icon={fa.faClose}
                className="bg-zinc-800 text-white text-xs p-2 rounded-full "
              ></FontAwesomeIcon>
            </Link>

            <span className="mx-4 text-xs font-extrabold text-zinc-800">
              {page_id ? "Edition" : "Nouveau"}
            </span>

            <FontAwesomeIcon
              onClick={captureScreen}
              icon={fa.faPhotoFilm}
              className=" bg-orange-500 text-white text-xs p-2 rounded-full mx-1"
            />
            <Link to={"/editpage/" + page_id} target={"editpage" + page_id}>
              <FontAwesomeIcon
                icon={fa.faEdit}
                className=" bg-blue-500 text-white text-xs p-2 rounded-full hidden"
              />
            </Link>
          </div>
          {actionSafe && (
            <div className="flex  justify-center items-center flex-col m-1 border-t border-zinc-200 p-2 ">
              <span className="mx-4 text-xs font-extrabold text-zinc-800">
                {actionSafe.msg}
              </span>
              <div className="flex justify-evenly w-full p-1">
                <FontAwesomeIcon
                  icon={fa.faStop}
                  className="bg-red-800 text-white text-xs p-2 rounded-full "
                  onClick={() => {
                    setActionSafe();
                  }}
                ></FontAwesomeIcon>
                <FontAwesomeIcon
                  icon={fa.faThumbsUp}
                  className=" bg-green-500 text-white text-xs p-2 rounded-full"
                  onClick={() => {
                    actionSafe.callback();
                    setActionSafe();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {page && (
        <header className=" relative">
          <div className="flex flex-row w-screen ">
            <div
              className={
                " w-full transition-all duration-500  z-1 grow relative flex flex-col items-start min-h-screen   " +
                (isMod ? " snap-container " : " normal-container")
              }
              id="page-content"
              {...(isMod ? {} : longPress)}
            >
              {(isEmptyPage ||
                isMod ||
                templates.find(
                  (t) => t.template_id + "" === "" + page.content.header
                )) && (
                <TemplateSwipe
                  label={"bloc_header"}
                  id={"header"}
                  layout={"top"}
                  nearestBloc={nearestBloc}
                  template_id={page.content.header}
                  content_id={page.content.headerContent_id}

                  palette={page.content.headerPalette}
                  onChangePalette={        (headerPalette) => {
                    data.updatePage({
                      ...page,
                      content: {
                        ...page.content,
                        headerPalette,
                      },
                    });
                  }}

                  onChangeContent={(content_id) => {
                    data_updatePage({
                      ...page,
                      content: {
                        ...page.content,
                        headerContent_id: content_id,
                      },
                    });
                  }}
                  onChangeTemplate={(template_id) => {
                    data.updatePage({
                      ...page,
                      content: { ...page.content, header: template_id },
                    });
                    resizingBlocs(_posBlocs);
                  }}
                  
                  onAddBloc={() => {
                    var body = page.content.body;
                    body.unshift({ template_id: 0 });

                    data.updatePage({
                      ...page,
                      content: { ...page.content, body },
                    });

                    setTimeout(() => {
                      startScrollLeft=null;
                    setChangingBloc("bloc_0")
                    //  setBlocVisible("bloc_0");
                    }, 1000);
                  }}

                  onDelete={()=>{
                    data.updatePage({
                      ...page,
                      content: { ...page.content,header:null , headerContent_id:null },
                    });

                  }}
  
                   
                />
              )}

              {(page.content.body.length > 0 || (!isMod && !isEmptyPage)
                ? page.content.body
                : [{ template_id: 0 }]
              ).map((body, i) => (
                <TemplateSwipe
                  key={i}
                  label={body.label}
                  nearestBloc={nearestBloc}
                  id={"bloc_" + i}
                  layout={"bloc"}
                  template_id={body.template_id}
                  content_id={body.content_id}

                  palette={body.palette}
                  onChangePalette={        (palette) => {
                    var _body = page.content.body;
                    _body[i].palette = palette;
                    data.updatePage({
                      ...page,
                      content: { ...page.content, body: _body },
                    });
                  }}


                  onChangeContent={(content_id) => {
                    var _body = page.content.body;
                    if(  _body[i].content_id+"" ===""+ content_id) return 
                    _body[i].content_id = content_id;
                    data_updatePage({
                      ...page,
                      content: { ...page.content, body: _body },
                    });
                  }}
                  onChangeTemplate={(template_id) => {
                    //  window.alert("onChangeTemplate");
                    var _body = page.content.body;
                    _body[i].template_id = template_id;
                    data_updatePage({
                      ...page,
                      content: { ...page.content, body: _body },
                    });
                  }}
                  onAddBloc={() => {
                    var body = page.content.body;
                    body = [
                      ...body.filter((_, j) => j <= i),
                      { template_id: 0 },
                      ...body.filter((_, j) => j > i),
                    ];

                    data.updatePage({
                      ...page,
                      content: { ...page.content, body },
                    });
                  
                    setTimeout(() => {
                      startScrollLeft=null;
                      setChangingBloc("bloc_" + (i + 1))
                      //setBlocVisible("bloc_" + (i + 1));
                    }, 500);
                  }}



                  onDelete={()=>{
                    var body = page.content.body;
                    body = body.filter((_, j) => j !== i)
                     

                    data.updatePage({
                      ...page,
                      content: { ...page.content, body },
                    });

                    setTimeout(() => {
                      startScrollLeft=null;
                      setChangingBloc()
                    }, 100);
                  }}
  


                />
              ))}

              {(isEmptyPage ||
                isMod ||
                templates.find(
                  (t) => t.template_id + "" === "" + page.content.footer
                )) && (
                <TemplateSwipe
                  label={"bloc_footer"}
                  id={"footer"}
                  layout={"bottom"}
                  nearestBloc={nearestBloc}
                  template_id={page.content.footer}
                  content_id={page.content.footerContent_id}



                  palette={page.content.footerPalette}
                  onChangePalette={        (footerPalette) => {
                    data.updatePage({
                      ...page,
                      content: {
                        ...page.content,
                        footerPalette
                      },
                    });
                  }}



                  onChangeContent={(content_id) => {
                    data_updatePage({
                      ...page,
                      content: {
                        ...page.content,
                        footerContent_id: content_id,
                      },
                    });
                  }}
                  onChangeTemplate={(template_id) => {
                    data.updatePage({
                      ...page,
                      content: { ...page.content, footer: template_id },
                    });
                  }}


                  onDelete={()=>{
                    data.updatePage({
                      ...page,
                      content: { ...page.content,footer:null , footerContent_id:null },
                    });

                  }}
  
                />
              )}
        
              {users.map((user) => (
                <UserPin
                  key={user.session_id}
                  id={user.selected}
                  user={user}
                  root_id={"page-content"}
                />
              ))}
            </div>
          </div>
        </header>
      )}
    </div>
  );
}

export default SetPage;
