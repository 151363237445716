import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";

import { useContext } from "react";
import { MfctContext } from "../context";

import { Link } from "react-router-dom";
import TemplateX from "./templates/TemplateX";
import { _GetSetTers,loadAllContentAsync} from "../tools/tpl";
import InputData from "./inputs/InputData";

import { data } from "../service/data";
import UserThumb from "./thumbs/UserThumb";
import VarContent from "./VarContent";
import { loadAction } from "../tools/actions";

function Content({ content, deleteContent, duplicateContent,className =" bg-white ", isEditable, schema }) {
  const { project ,authUser,getTemplates,getSchemas} = useContext(MfctContext);
 // const [displayTemplate, setDisplayTemplate] = useState();

const [contents,setContents]=useState([content])
const templates = getTemplates()
const schemas = getSchemas()
const Actions = ({isEditable})=>   ( <div className=" border-t border-zinc-200 p-2 w-full flex flex-row justify-end px-4 items-center text-xs text-white ">
<div className="text-black" ><UserThumb user_id={content.owner_id} /></div>
{ isEditable &&  data.isGranted( authUser, content,"content","edit")    && 
<Link
  to={
    content.template_id
      ? `/contentTemplate/${content.content_id}`
      : `/content/${content.content_id}`
  }
  className="appbutton mx-2 bg-green-700 p-1 px-3 rounded-full"
>
  <FontAwesomeIcon icon={fa.faEdit} />{" "}
</Link>}
{content.is_schema  &&
     <Link to={`/templates/?schema_id=${content.content_id}`} className='mx-1 text-green-700 p-1 px-3 rounded-full' ><FontAwesomeIcon icon={fa.faWindowRestore} /> </Link>
}
{data.isGranted( authUser, content,"content","duplicate")   && duplicateContent && (
  <div
    className="appbutton mx-2 bg-blue-700  p-1 px-3 rounded-full"
    onClick={() => duplicateContent(content)}
  >
    <FontAwesomeIcon icon={fa.faPaste} />
  </div>
)}
{ isEditable &&
data.isGranted( authUser, content,"content","delete")    &&  deleteContent && (
  <div
    className="appbutton mx-2 bg-red-700  p-1 px-3 rounded-full"
    onClick={() => deleteContent(content)}
  >
    <FontAwesomeIcon icon={fa.faRemove} />
  </div>
)}
</div>)

const displayTemplate = useMemo(()=>{

  if (schema && schema.content?.display_template_id) {
    var _template =  templates.find(t=>t.template_id === parseInt( schema.content.display_template_id)) // await  data.getTemplate( parseInt( schema.content.display_template_id));
    
   return _template
    console.log(_template);  
  }
}, [schema,templates])


  useEffect(() => {
  /*  const initTemplate = async () => {
      if (schema && schema.content?.display_template_id) {
        var _template =  templates.find(t=>t.template_id === parseInt( schema.content.display_template_id)) // await  data.getTemplate( parseInt( schema.content.display_template_id));
        
        setDisplayTemplate(_template);
        console.log(_template);  
      }
    };
 */
    loadAllContent();
  // initTemplate();
  }, [project, schema]);

const loadAllContent = async()=>{
  if (schema && schema.content?.display_template_id) {
    var _template =  templates.find(t=>t.template_id === parseInt( schema.content.display_template_id)) // await  data.getTemplate( parseInt( schema.content.display_template_id));
 
    
if(_template.props_from_templates && _template.props_from_templates[  _template.template_id]?.props)
    for(let prop of    Object.values( _template.props_from_templates[  _template.template_id].props)){
      if(prop.computed){
    console.log("Loading Action" + prop.name , prop.computed ) // Action qui chargent
       await loadAction(prop.computed)
      }
    }

 var _contents = await loadAllContentAsync(content,null,schemas,true)

 
console.log("loadAllContent " + content.content_id  ,{_contents,schemas});

  setContents(_contents)


        

  }
}


const GetSetTers = (template, getSelf, setSelf) => {

  return { ..._GetSetTers( template,getSelf,setSelf,"global",[{content_id:"global",content:{self:{content_id:content.content_id}}},...contents],displayTemplate.props_from_templates ??{}  , [schema,...schemas],{} )
  ,GetSetTers}
  
  
    };


  if (displayTemplate && !content.is_query) {
    return (
      <div
        className={
          " relative rounded-xl overflow-hidden  border flex flex-col  justify-between boDCrder-zinc-500 shadow text-xs   " +
          className
        }
        style={{ minHeight: "10px" }}
      >
      { !displayTemplate.props_from_templates &&   " Pas de props_from_templates !!!!"}
        <TemplateX
        id = {"content_" + content.content_id  }
          content={
            { 
              ...GetSetTers(displayTemplate)

          }    }
          template={displayTemplate.structure} 
templates={templates}
        
        />
      <Actions  isEditable={isEditable}/>
      </div>
    );
  }

  return (
    <div
      className={
        " relative rounded-xl overflow-hidden  border flex flex-col  justify-between boDCrder-zinc-500 shadow text-xs   " +
        className
      }
      style={{ minHeight: "10px" }}
    >
      <div className="flex flex-row w-full gap-2 p-2">
        {!content.content?.value && (
          <FontAwesomeIcon
            className="w-8 h-8"
            icon={
              content.is_schema && content.content?.icone 
                ? fa[content.content.icone]
                : schema && schema.content?.icone
                ? fa[schema.content.icone]
                : fa.faQuestionCircle
            }
          />
        )}

        <div className="flex flex-col gap-1">
          {content.content.title && (
            <div className="font-bold ">
              {isEditable && (content.content.title ?? content.content_id)}
            </div>
          )}
          <div className="">
            {" "}
            {schema?.title} {content.template_id && "Contenu de Bloc"}{" "}
            {content.is_schema && "Schéma"}
          </div>
        </div>
        { !isEditable &&
          <VarContent data={content.content}  className={"text-xs"}/>
        }
      </div>
      {content.content?.value && schema && (
        <InputData
          className={" w-20 h-20 "}
          prop={{}}
          value={content.content.value}
          domain={content.content?.domain}
          schema={schema}
        />
      )}

<Actions  isEditable={isEditable}/>
      
    </div>
  );
}

export default Content;
