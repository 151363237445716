import { useEffect } from "react";
import { useState } from "react";
import { data } from "../../service/data";
import InputData from "./InputData";
import VarContent from "../VarContent";
import SelectDropDown from "./SelectDropDown";

const schemas = {};

const InputForSchemaProp = ({
  id,
  name,
  schema_id,
  label,
  onChange,
  value,
  className,
  placeholder,
  context,
  options,
}) => {
  const [prop, setProp] = useState();
  const [schema, setSchema] = useState();
  useEffect(() => {
    const loadProp = async () => {
      if (!schemas[schema_id]) {
        schemas[schema_id] = await data.getContent(schema_id);
      }
      if (schemas[schema_id]) {
        var _prop = schemas[schema_id].content.schema.find(
          (p) => p.name === name
        );
        if (_prop) {
          if (!schemas[_prop.schema_id]) {
            schemas[_prop.schema_id] = await data.getContent(_prop.schema_id);
          }
          if (schemas[_prop.schema_id]) {
            setSchema(schemas[_prop.schema_id]);
            setProp(_prop);
          }
        }
      }
    };

    loadProp();
  }, [name, schema_id]);

  if (!prop) return <></>;

  return (
    <>
     { schema?.content.is_value  && <InputData
        id={id}
        prop={{ ...prop, name: label ?? prop.name }}
        schema={schema}
        value={value}
        onChange={onChange}
        className={className}
        placeholder={placeholder}
        context={context}
        options={ options}
      />}
      { !schema?.content.is_value &&
      <><SelectDropDown 
         id={id+"s"}
optionsFilter={     {  schema_id : schema.content_id } }

    label={label ?? prop.name}
fieldSearch={ schema.content.entity    ?   { "app":"name","user":"username" }[schema.content.entity]    :  "title" }
fieldId={ schema.content.entity    ?   { "app":"app_id","user":"user_id" }[schema.content.entity]    :  "content_id" }
         value={value}
         onChange={ (v)=>{
          var fieldId =schema.content.entity    ?   { "app":"app_id","user":"user_id" }[schema.content.entity]    :  "content_id"
          onChange(v[fieldId])}
          }

         className={className}
         placeholder={placeholder}
displayTemplateId={schema.content.display_template_id}
         options={ options}
      /></> }
    </>
  );
};
export default InputForSchemaProp;
