import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Image from "../Image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import MapContent from "../map/MapContent";
import { ProductComponent } from "../products/ProductComponent";
import { getApp } from "../../service/mftc-api";

import TemplateInput from "./TemplateInput";
import { FlatObject, RecomposeContent } from "../../tools/tpl";
import { data } from "../../service/data";
import { closeModal, showModal } from "../modal";
import { runAction, runActionSync } from "../../tools/actions";
import { metaStructureMapping } from "../../tools/meta";

//import { MfctContext } from "../../context";
const _components = { ProductComponent: ProductComponent };

const TemplateX = React.memo(
  ({
    //function TemplateX({
    label,
    template,
    internals,
    setInternal,
    palette,
    _internalContent,
    content,
    templates,
    schemas,
    contents,
    id,
    selectTpl,
    selected,
    safeDisplay,
    parentClick,
    schema_prop,
    snapAlign,
    isFirst,
    navigate,
    self,
    setLocalContent,
  }) => {
    if (!content) content = {};


    if (isFirst) console.log("display Template X first ", isFirst);
    if(!window.content)window.content={} 
    window.content[id]=content
    if(!window.structure)window.structure={} 
    window.structure[id]=template
    const ref = useRef();
    //const {project} = useContext(MfctContext)

    const [transitionState, setTransitionState] = useState(
      !template.condition ? "" : "Unknown"
    );
    const [internalContent, setInternalContent] = useState({});
    const [popupTemplate, setPopupTemplate] = useState();


    const [isAnimating, setIsAnimating] = useState(false); // Pour gérer le début des transitions
    const [isVisible, setIsVisible] = useState(false); // Gère si le composant doit être visible ou caché
  
    // Calculer les classes avec useMemo en fonction des propriétés
    // Attention le premier container template n'interagfit pas avec les internal propres
    const _setInternal = (key, value) => {
      console.log( id + "_setInternal _set_" + key,content["_set_" + key])
      if (content["_set_" + key]) {
        content["_set_" + key].do(value);
        return;
      }
      if (setInternal) {
        console.log("setInternal " + id + " =  > " + key, value);
        setInternal(key, value);
        return;
      }
      console.log("setInternalContent " + id + " =  > " + key, value);
      setInternalContent((_content) => ({ ..._content, [key]: value }));
    };

    const _get =    (key,index) => {
      if (!key && (template.schema_prop ?? template.schema_label)) {
        key = template.schema_prop ?? template.schema_label;
      }
      if(!key  && template.schema_id  ) key = template.suid
       if(!key)return
      if (content["_get_" + key]) {

        var value =   content["_get_" + key].do(index,content)    // le content joue le role de changeur de context en profondeur : une fonction _get_Executant.do() peut rendre une liste et la fonction renvoyee par content rendre un seul Executant
 //       console.log( "no value key for _get_" +key ,{ value, stack :content["_get_" + key]})
 //console.log( id + " get_"+key, value )
     if(value)   return value;
      }
   //   return content[key];
    };

    const [updateTemplate, setUpdateTemplate] =useState()

useEffect(()=>{
  var    key = template.schema_prop ?? template.schema_label;
if(template.schema_prop ?? template.schema_label)
  {
    if (content["_loading_" + key] &&   (!window.dataSource || !window.dataSource["_loading_" + key])) {
      if(!window.dataSource)window.dataSource={}
      window.dataSource["_loading_" + key]=true
      console.log("Template Loading DataSource")

      content["_loading_" + key].do(()=>{ console.log("Refresh Template " + id + " " +Date.now()); setUpdateTemplate(Date.now()) })

      // Charge les dataSource si besoin
    }
    
  }
},[content,template])

const _getText=(index)=>{

var value =_get()
if(typeof value === "object")
  {
    console.log(id  + " _getText error " , 
    
      {value ,key:template.schema_prop ?? template.schema_label, stack:      content["_get_" + template.schema_prop ?? template.schema_label]?.stack} )
//value = "object"
value = (template.schema_prop ?? template.schema_label )+" : "+JSON.stringify(value)

  }
  var needParams = template.schema_prop ?? template.schema_label
if(template.isList && value && Array.isArray(value)) value = value[index]
//console.log( "_getText " +needParams,{id,value,  needParams,content }  )
return  value   ??  (needParams ? 
    <div className="loading-text">
      {jokerize(( template.default_value ?? template.text  ) )}
    </div> : jokerize(( template.default_value ?? template.text ) ))

}


    const jokerize = (text) => {
      if (!text) return text;
      const regex = /\{([^}]+)\}/gu;
      return text.replace(regex, (match, p1) => {
        return _get(p1.trim()) ?? match;
      });
    };
    useEffect(() => {
      if (
        !template.isList &&
        !template.template_id &&
        id === selected &&
        setLocalContent
      )
        setLocalContent(content);
    }, [id, content, setLocalContent, selected]);

    useEffect(() => {
      if (ref && ref.current) {
        var obj = ref.current;
        var listeners = [];
        if (template.vars) {
          template.vars.forEach((v) => {
            listeners.push({
              event: v.event,
              handler: () => {
                var result;
                eval(v.code);
                if (v.name) {
                  console.log(" UI VAR " + v.name, result);

                  setInternal(v.name, result);
                }
              },
            });
          });

          listeners.forEach((listener) =>
            obj.addEventListener(listener.event, listener.handler)
          );
        }

        return () => {
          listeners.forEach((listener) =>
            obj.removeEventListener(listener.event, listener.handler)
          );
        };
      }
    }, []);

    const background = useMemo(() => {
      if (!template.background) return;
      var name = template.background;
      var parts = template.background.split("[*");

      for (var i = 0; i < parts.length - 1; i++) {
        var nameclass = parts[i].split(".").pop();

        if (template["iBg" + nameclass]) {
          name = name.replace("*", template["iBg" + nameclass]);
        }
      }
      return name;
    }, [template]);



    const _template_className = useCallback((index) => {
      var result = template.className;
      if (template.cssConditions) {
        template.cssConditions.forEach((cssCondition) => {
          var { etat, className, ajout } = cssCondition;
          var valueExpected = true;

          if (!etat) return;
          if (etat[0] === "-") {
            etat = etat.replace("-", "");
            valueExpected = false;
          }
          var parts = etat.split(":");
          var condition = false;
          if (parts.length === 2) {
            etat = parts[0].trim();
            let value = parts[1].trim();

            condition = _get(etat,index) === value;
          } else {
            var _parts = etat.split("=");
            if (_parts.length === 2) {
              etat = _parts[0].trim();
              let value = _parts[1].trim();
              if (typeof _get(value,index) === "string") {
                console.log(" cssCondition jokerize " +etat, cssCondition, etat,  jokerize(_get(value,index) ))
                condition = _get(etat,index) === jokerize(_get(value));
              } else {
                console.log(" cssCondition  _get(etat) === _get(value) " +etat, cssCondition, etat,  _get(value,index)) 
                condition = _get(etat,index) === _get(value,index);
              }
            } else {
              condition = _get(etat,index);
            }
          }
          //   console.log(" cssCondition "+etat, cssCondition, etat,  _get(etat,index) )
          if (!!condition === valueExpected) {
          //  console.log("CONDITION REUSSIE !!  cssCondition "+etat, cssCondition )
            // ClassName
            if (ajout) {
             
              result += " " + className;
            } else {
              result = className;
            }
          }
        });
      }

      if (result && palette) {
        var keysPalette = Object.keys(palette);
        result = result
          .split(" ")
          .map((classe) => {
            for (let color of keysPalette) {
              if (classe.split("-" + color).length > 1) {
                classe = classe.split("-" + color).join("-" + palette[color]);
                break;
              }
            }
            return classe;
          })
          .join(" ");
      }


if(content.showHidden)  result =(""+result).replace(/\bhidden\b/g, 'hidden_show') //split("hidden").join("hidden_show")  //selected ===id?  "" :

if(content.cleanResponsiveClassName){

var _result =content.cleanResponsiveClassName(result)

//if(result.split(" ").join("")!== _result.split(" ").join(""))   console.log(id+  " YESSS result  cleanResponsiveClassName !! " +_result + " ////// => " + result)
result= _result
    }

    return result;
    },[template,_get,jokerize,content.showHidden,   content.cleanResponsiveClassName, palette])



    const isOff = useMemo(() => {
      return (_template_className()+"")?.split(" ").includes("off") 
    }, [_template_className]);
  
    useEffect(() => {
      if (!isOff) {
        // Démarre l'animation d'apparition
        setIsAnimating(true); // Active la transition avec 'appear-start'
        setIsVisible(true); // Rend le composant visible
        setTimeout(() => setIsAnimating(false), 10); // Passe à 'appear-end' après un délai
      } else {
        // Démarre l'animation de disparition
        setIsAnimating(true); // Active la transition avec 'disappear-start'
        setTimeout(() => {
          setIsAnimating(false); // Termine l'animation avec 'disappear-end'
          setIsVisible(false); // Cache le composant après la fin de la disparition
        }, 300); // Durée totale de la transition
      }
    }, [isOff]);
  
  
    if (!isVisible && isOff) {
      return <></>;
    }
  

// const {className,computedStyle } =getClassAndStyle(index)
const getClassStyleAndDynamicsProps=(index)=>{
  var className =
  " templateX " +
  (snapAlign ? " snap-align " : "") +
  _template_className(index)   ;


var computedStyle = template.cssAttributes ?? {};

if (safeDisplay) className = className.replace("fixed", "");

if (className) {
  var classStyles = className.split(" ");
  const shortCutstyles = {
    snapContainer: { scrollSnapType: "x mandatory" },
    snapElement: { scrollSnapAlign: "center" },
  };
  const shortCutClasses = {
    horizontal: "flex flex-row",
    vertical: "flex flex-col",
    centre: " items-center justify-center",
  };
  const shortCutAttrs = { w: "width", h: "height", t: "fontSize" };
  classStyles.forEach((strclass) => {
    if (strclass.includes("=")) {
      var [attr, value] = strclass.split("=");
      attr = shortCutAttrs[attr] ?? attr;
      computedStyle[attr] = value;
    } else if (shortCutClasses[strclass])
      className += " " + shortCutClasses[strclass];
    else if (shortCutstyles[strclass])
      computedStyle = { ...computedStyle, ...shortCutstyles[strclass] };
  });
}

var dynamicsProps = {};


if (template.attrDatas) {
  Object.keys(template.attrDatas).forEach((key) => {
    if (template.attrDatas[key].type === "schema_labelData") {
      dynamicsProps[key] = _get(template.attrDatas[key].value,index)?? "";
    } else if (template.attrDatas[key].type === "eventHandlerData") {
      dynamicsProps[key] = (e) => {
        try {
          eval(template.attrDatas[key].value);
        } catch (e) {
          console.log("Error", e);
        }
      };
    } else if (template.attrDatas[key].type === "expressionData") {
      var result = null;
      try {
        let { self } = content;
        eval("result=" + template.attrDatas[key].value);
      } catch (e) {
        console.log("Error", e);
      }
      dynamicsProps[key] = result;
    } else if (template.attrDatas[key].type === "computeData") {
      var result = null;
      try {
        eval(template.attrDatas[key].value);
      } catch (e) {
        console.log("Error");
      }
      dynamicsProps[key] = result;
    } else dynamicsProps[key] = jokerize(template.attrDatas[key].value);

    var parts = key.split(".");
    if (parts.length > 1 && parts[0] === "style") {
      computedStyle[parts[1]] = dynamicsProps[key];
      delete dynamicsProps[key];
    }
  });
}

if (content?.dynamicsProps) {
  dynamicsProps = { ...dynamicsProps, ...content.dynamicsProps };
  //delete content.dynamicsProps
}

if (
  CustomTag === "img" &&
  (!("src" in dynamicsProps) || !dynamicsProps["src"])
)
  dynamicsProps["src"] =
    _get() ??
    (template.default_value ?? template.imageUrl  ) ?? 
    (safeDisplay
      ? process.env.REACT_APP_MFTC_SERVER_URL_FILES + "medias/user.png"
      : null);
if (CustomTag === "img" && !("alt" in dynamicsProps))
  dynamicsProps["alt"] = "imgXXX";
if (CustomTag === "img" && className.split("object-").length === 1)
  className += " object-cover";
return {className,computedStyle,dynamicsProps}
}



    var schema =
      schemas && template.schema_id
        ? schemas.find((c) => c.content_id + "" === "" + template.schema_id)
        : null;
        

    const TriggerAction = async (event, e) => {
      if (template.actions) {
        for (var ea of template.actions) {
          if (ea.Action && ea.Event === event) {
            try {
              if (ea.Action.asyncrhone)
                await runAction(
                  e,
                  { ...ea.Action, self },
                  {...content, navigate},
                  setInternal,
                  safeDisplay
                );
              else
                runActionSync(
                  e,
                  { ...ea.Action, self },
                  {...content, navigate},
                  setInternal,
                  safeDisplay
                );
            } catch (e) {
              console.log("Error", e);
            }
          }
        }
      }
    };

    const hasEventAction = (event) => {
      if (
        event === "onClick" &&
        (template.onClick ||
          template.Link ||
          template.schema_link ||
          template.schema_url)
      )
        return true;
      return (
        template.actions &&
        template.actions.find((ea) => ea.Action && ea.Event === event)
      );
    };

    // Click Sur l'élément
    var onClick = async (e, childEvent, safe) => {
      console.log("*********  Click", id, template, content);
      if (!id) return;
      if (!childEvent) {
        if (selectTpl && typeof selectTpl === "function") {
          var _id = id
            .split(".")
            .map((c) => c.split(":")[0])
            .join(".");

          e.stopPropagation();
          if (selectTpl(_id, template) === "stop") return;
        }
      }

      if (parentClick) parentClick(e);

      await TriggerAction("onClick", e);

      if (!content) return;
      if (
        template.popupTemplate &&
        template.onClick?.split("Popup:").length > 1
      ) {
        if (safeDisplay && typeof safeDisplay === "function" && !safe)
          return safeDisplay(" Voulez-vous ouvrir le popup  ? ", () =>
            onClick(e, childEvent, true)
          );

        var popup_id = template.onClick
          ?.replace("Popup:", "")
          .replace("<", "")
          .replace(">", "");

        if (popup_id === "Self")
          setPopupTemplate({
            template_id: parseInt(template.popupTemplate),
            content: content,
          });
        if (true || content[popup_id])
          setPopupTemplate({
            template_id: parseInt(template.popupTemplate),
            popup_id,
            content: content[popup_id]?.content,
          });
      }

      var link = template.Link;
      if (link) {
        console.log("Clicking Link", link, content);
      }
      if (link && content[link]) {
        if (safeDisplay && typeof safeDisplay === "function" && !safe)
          return safeDisplay(" Voulez-vous ouvrir le lien  ? ", () =>
            onClick(e, childEvent, true)
          );

        navigate(
          (process.env.REACT_APP_ENV === "dev" || getApp() === "pro" //process.env.REACT_APP_MFTC_APP!=="client"
            ? "/domain/" + content.domain
            : "") + content[link]
        );
      }
      if (template.schema_url && content[template.schema_url]) {
        if (safeDisplay && typeof safeDisplay === "function" && !safe)
          return safeDisplay(" Voulez-vous ouvrir le lien  ? ", () =>
            onClick(e, childEvent, true)
          );
        var dynamic_link = content[template.schema_url];
        window.open(dynamic_link, "_blank");
      }
      if (template.schema_link && content[template.schema_link]) {
        if (content[template.schema_link][0] === "#") {
          if (safeDisplay && typeof safeDisplay === "function" && !safe)
            return safeDisplay(" Voulez-vous aller sur l'ancrage  ? ", () =>
              onClick(e, childEvent, true)
            );
          var obj = document.querySelector(content[template.schema_link]);
          if (obj) {
            console.log("scroll by Onclick  ", obj.id, obj);
            obj.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
          }
        } else {
          if (safeDisplay && typeof safeDisplay === "function" && !safe)
            return safeDisplay(" Voulez-vous ouvrir le lien  ? ", () =>
              onClick(e, childEvent, true)
            );
          var dynamic_link = content[template.schema_link];

          if (content["content_id"])
            dynamic_link = dynamic_link.replace(":id", content["content_id"]);
          navigate(
            (process.env.REACT_APP_ENV === "dev" || getApp() === "pro"
              ? "/domain/" + content.domain
              : "") + dynamic_link
          );
        }
      }

      var action = template.onClick;
      if (action) {
        if (safeDisplay && typeof safeDisplay === "function" && !safe)
          return safeDisplay(" Voulez-vous démarrer l'action ? ", () =>
            onClick(e, childEvent, true)
          );
        let _content;
        switch (action) {
          case "CONTENT_CREATE":
          case "CONTENT_UPDATE":
          case "CONTENT_SET":
            //console.log("InternalContent ********* " , _internalContent);
            _content = RecomposeContent(content);
            //window.alert(JSON.stringify(_content))
            if (_content && !(_content.content_id + "").includes("test"))
              data.save_content(_content).then(() => {
                navigate(-1);
              });

            return;

          case "CONTENT_DELETE":
            _content = RecomposeContent(content);
            if (_content && _content.content_id) {
              showModal(
                "Are you sure you want to delete  content n°" +
                  _content.content_id +
                  "?",
                {
                  key: "confirm",
                  Content: (
                    <div className="flex gap-2 items-center justify-center  ">
                      <div
                        className=" p-2 bg-red-500 text-white "
                        onClick={() => {
                          closeModal("confirm");
                        }}
                      >
                        {" "}
                        Annuler{" "}
                      </div>
                      <div
                        className=" p-2 bg-green-500 text-white "
                        onClick={() => {
                          data.delete_content(_content.content_id).then(() => {
                            navigate(-1);
                          });

                          closeModal("confirm");
                        }}
                      >
                        {" "}
                        Confirmer{" "}
                      </div>
                    </div>
                  ),
                }
              );
            }

            return;
          default:
        }
        if (action.includes("SwitchInternal_")) {
          var i = action.split("_")[1];
          console.log("_Internal_" + i + " = " + !content["_Internal_" + i]);
          setInternal("_Internal_" + i, !content["_Internal_" + i]);

          return true;
        }

        if (content[action]) {
          if (safeDisplay && typeof safeDisplay === "function" && !safe)
            return safeDisplay(" Voulez-vous démarrer l'action  ? ", () =>
              onClick(e, childEvent, true)
            );
          console.log("Il y a de l'action " + action);
          if (typeof content[action] === "string") eval(content[action]);
          else {
            console.log("Execution de l'action " + action);
            content[action]();
          }
        }
      }

      return true;
    };

    var templateValue = _get();


    var _listElements = [];

    if (template.isList) {
      if (templateValue) {
        if (!Array.isArray(templateValue)) templateValue = [templateValue];

        _listElements = [];
        templateValue.forEach((value, i) => {
          if (!value || !value.is_query)
            _listElements.push(
              typeof value === "object"
                ? { ...value, index: _listElements.length }
                : value
            );
          else if (content["content_query_" + value.content_id]) {
            console.log(
              template.schema_label + " query",
              content["content_query_" + value.content_id]
            );
            content["content_query_" + value.content_id].forEach(
              (subvalue, k) => {
                _listElements.push({
                  ...FlatObject(subvalue),
                  index: _listElements.length,
                });
              }
            );
          }
          //  return typeof value === "object" ? { ...(value), index: i } : value;
        });

     //   console.log("List Elements =", { _listElements });
      } else {
        /*  console.log(
        template.listLength + " elements",
        typeof template.listLength
      );*/
        var n = template.listLength ? parseInt(template.listLength) : 1;

        _listElements = Array(n)
          .fill({})
          .map((c, i) => {
            return { ...c, index: i };
          });
        _listElements = Array(n).fill(null);
        //   console.log(n, _listElements);
      }
    } else {
      if (
        templateValue &&
        typeof templateValue === "object" &&
        templateValue.is_query &&
        content["content_query_" + templateValue.content_id]
      ) {
        console.log(
          template.schema_label + " query",
          content["content_query_" + templateValue.content_id]
        );
        content["content_query_" + templateValue.content_id].forEach(
          (subvalue, k) => {
            _listElements = [FlatObject(subvalue)];
          }
        );
      } else if (templateValue && typeof templateValue === "object") {
        _listElements = [templateValue];
      } else {
        _listElements = [templateValue];
      }
    }

    //  _listElements  =_listElements.filter((value)=> (!value || typeof value !== "object" || !value.is_query) )
    //const [ listElements,setListElements]=useState(_listElements)
    var listElements = _listElements;

    if (transitionState === "Unknown") return <></>;
    if (transitionState === "Hidden") return <></>;

    if (template.template_id) {
      //Fonctionne avec schema

      if (!templates) return <div> No templates loaded</div>;

      var tpl =
        template.template ??
        templates.find((t) => t.template_id + "" === "" + template.template_id);

      if (!tpl) return <div>Template {template.template_id} Not Found</div>;
    

//console.log(id + " Loading Sub Template " + tpl.title ,{ template,listElements} )
var schema_key = template.schema_prop ??   template.schema_label ;
var getFunction = content["_get_"+ schema_key] ?? { do:()=>{},stack:["No get Func"]}
var setFunction = content["_set_"+ schema_key]?? { do:()=>{},stack:["No set Func"]}
var structure={...tpl.structure}
var  meta_tpl ={}
if(template.is_meta) {

 structure = metaStructureMapping(structure,template)

console.log("meta_structure " +schema_key , {template,structure, getFunction,setFunction})

meta_tpl.template_id = tpl.template_id+"-" + template.suid
meta_tpl.schema_id = template.schema_id
}

window.structure[id+"-tpl"]=template

      return listElements.map((element, index) => {

        const {className,computedStyle,dynamicsProps } =getClassStyleAndDynamicsProps(template.isList ? index:null)

        var GetElement ={ do : () => {
       
          if (template.isList) {
           return getFunction.do(template.isList ? index:null);
          } else {
if(true || schema_key==="title")         {
//   console.log( id +" GetElement " +   schema_key ,{schema_key,getFunction ,value:  getFunction.do()})
        }        var value =  getFunction.do();
       //  console.log("GetElement ",{ prop : template.schema_prop,label :   template.schema_label,value  }    )
          return value
          }

        },stack:[...getFunction.stack,id + " GetElement " +(template.isList ? index :"") ]};

        var SetElement ={ do :async (value) => {
     
          if (template.isList) {
       
       await    setFunction.do(value, index);
          } else {
          await  setFunction.do(value);
          }
        }, stack: [...setFunction?.stack,id + " SetElement "  ]   } ;

      //  console.log(" GetElement preparation",{ prop : template.schema_prop,label :   template.schema_label  }    )


        var TemplateG = _components[element?.content?._component] ?? TemplateX;
        var _id = id + (index > 0 && template.isList ? ":" + index : "");
        var BF = {};
        if (element && typeof element === "object") {
          BF = element;
        }
        element = FlatObject(element);
        if (element && typeof element === "object") {
          element.BFElement = BF;
        }

        return (
          <TemplateG
            template={{
              ...structure,
              className: (
                (structure.className ?? "") +
                " " +
                (className ?? "")
              ).trim()
            }}
            internals={internals}
            setInternal={_setInternal}
            content={{
              ...content,

              ...(typeof element === "object" ? element : {}),
              ...(element?.content ? element.content : {}),
              ...(_internalContent ?? internalContent),
              self: element,
            selfName:  template.schema_label ?? self,// tschema_prop ?? template.schema_label ?? self,
              ...(content?.GetSetTers ? content?.GetSetTers(
                {...tpl,...meta_tpl},
                GetElement,
                SetElement,
                {setInternal ,self:"self",content ,element_id:id} 
              ):{}   ),
            dynamicsProps: dynamicsProps
            
            }}
            palette={palette}
            self={ template.schema_label ?? self}  //{tschema_prop ?? template.schema_label ?? self}
            _internalContent={_internalContent ?? internalContent}
            safeDisplay={safeDisplay}
            templates={templates}
            schemas={schemas}
            contents={contents}
            navigate={navigate}
            selectTpl={
              selectTpl
              /*   typeof selectTpl === "function"
                ? (...args) => {
                    if (document.getElementById(id + "-edited"))
                      selectTpl(...args);
                    else selectTpl(id);
                  }
                : () => {}*/
            }
            id={_id}
            key={_id}
            selected={selected}
            schema_prop={template.schema_prop ?? schema_prop}
            parentClick={
              parentClick ??
              (hasEventAction("onClick") ? (e) => onClick(e, true) : null)
            }
            snapAlign={snapAlign}
            setLocalContent={setLocalContent}
          />
        );
      });
    }



    const PopupTemplate = () => {
      //creer une animation css
      const [style, setStyle] = useState(" opacity-0 scale-0 ");
      const [hide, setHide] = useState(false);
      //creer une animation

      useEffect(() => {
        if (popupTemplate)
          setTimeout(() => {
            setStyle("opacity-100 scale-100 ");
            const scrollY =
              document.documentElement.style.getPropertyValue("--scroll-y");
            const body = document.body;
            body.style.position = "fixed";
            body.style.top = `-${scrollY}`;
          }, 20);
      }, []);

      useEffect(() => {
        if (hide) {
          const body = document.body;
          const scrollY = body.style.top;
          body.style.position = "";
          body.style.top = "";
          window.scrollTo(0, parseInt(scrollY || "0") * -1);
          setStyle("opacity-0  scale-0");
          setTimeout(() => setPopupTemplate(), 200);
        }
      }, [hide]);

      if (!popupTemplate) return <></>;

      console.log("popuptemplate", popupTemplate, content);
      return (
        <div
          className={
            "  transition-all  duration-200 fixed z-50  top-0 left-0 w-screen h-screen bg-black bg-opacity-40 flex flex-row items-center justify-center " +
            style
          }
        >
          <TemplateX
            internals={internals}
            templates={templates}
            template={
              templates.find((t) => t.template_id === popupTemplate.template_id)
                .structure
            }
            palette={palette}
            self={ template.schema_label ?? self} //{tschema_prop ?? template.schema_label ?? self}
            schemas={schemas}
            contents={contents}
            content={popupTemplate.content}
            snapAlign={snapAlign}
            navigate={navigate}
          />
          <div className="top-0 left-0 absolute safearea w-full h-40  flex flex-row justify-start ">
            <FontAwesomeIcon
              icon={fa.faArrowLeft}
              className="m-4 w-4 h-4 p-2 bg-white rounded-full text-black shadow"
              onClick={() => setHide(true)}
            />
          </div>
        </div>
      );
    };

    if (template.tag === "icon" || template.schema_id===48) {
      return listElements.map((element, index) => {


        const {className,computedStyle,dynamicsProps } =getClassStyleAndDynamicsProps(template.isList ? index:null)

        var _id = id + (template.isList && index > 0 ? ":" + index : "");
        return (
          <FontAwesomeIcon
            ref={ref}
            id={_id}
            icon={fa[element ?? (template.default_value ??  template.icon   )  ?? "faQuestion"]}
            className={className + "   "}
            style={computedStyle}
            onClick={onClick}
     
          />
        );
      });
    }
    if (template.tag === "input") {
      return listElements.map((element, index) => {
        var _id = id + (template.isList && index > 0 ? ":" + index : "");
        const {className,computedStyle,dynamicsProps } =getClassStyleAndDynamicsProps(template.isList ? index:null)
        return (
          <TemplateInput
            template={template}
            self={self}
            id={_id}
            palette={palette}
            element={element}
            className={className + "   "}
            style={computedStyle}
            onClick={onClick}
            internals={internals}
            setInternal={_setInternal}
            content={{
              ...content,

              ...(element?.content ??
                (typeof element === "object" ? element : {})),

              ...(_internalContent ?? internalContent),
            }}
            _internalContent={_internalContent ?? internalContent}
            templates={templates}
            schemas={schemas}
            contents={contents}
            domain={content.domain}
            selectTpl={selectTpl}
            selected={selected}
            safeDisplay={safeDisplay}
            parentClick={
              parentClick ??
              (hasEventAction("onClick") ? (e) => onClick(e, true) : null)
            }
            schema_prop={template.schema_prop ?? schema_prop}
          />
        );
      });
    }

    if (template.tag === "map" || template.schema_id===107) {
      const {className,computedStyle,dynamicsProps } =getClassStyleAndDynamicsProps()
      
      return (
        <>
  <MapContent
          id={id}
          ref={ref}
          map={ _get()    }
          style={computedStyle}
          className={className + "   "}
          onClick={onClick}
        /></>
      );
    }

    var CustomTag = template.tag === "text" ? "div" : template.tag ?? "div";
    if (template.tagName === "FontAwesomeIcon") CustomTag = FontAwesomeIcon;
    else if (template.tagName) CustomTag = template.tagName;

    if (!content.setContent)
      content.setContent = (key, value) => setInternal(key, value);
    if (!content.getContent) content.getContent = (key) => content[key] ?? null;

    return listElements.map((element, index) => {


    
      var _id = id + (template.isList && index > 0 ? ":" + index : "");

    // element = FlatObject(element);

      let _content ={...content};
      if (template.isList) {
          
      
        // Une liste d'element non template
        // Une structure qui se repete sans template
        var key = template.schema_prop ?? template.schema_label;
        if(key){

         if(content["_get_"+key]   )_content["_get_"+key] = {...content["_get_"+key], do:()=>content["_get_"+key].do(index) } 

         if(content["_set_"+key]   )_content["_set_"+key] = {...content["_set_"+key], do: (value)=>content["_set_"+key].do(value,index) } 

         if(content["_post_"+key]   )_content["_post_"+key] = {...content["_post_"+key], do: (value)=>content["_post_"+key].do(value,index) } 
      //  if (content[key]) _content = { ...content, [key]: content[key][index] };
       // if(content["_set_" + key]) _content["_set_" + key] =(value)=> content["_set_" + key](value,index)
       //   if(content["_get_" + key]) _content["_get_" + key] =(value)=> content["_get_" + key](value,index)


          }

        return (
          <TemplateX
            template={{ ...template, isList: false }}
            internals={internals}
            palette={palette}
            setInternal={_setInternal}
            content={{
            
              ..._content,

           //   ...(element?.content ??           (typeof element === "object" ? element : {})),
              ...(_internalContent ?? internalContent),
              ["index_" + key]: index,
            }}
            self={self}
            _internalContent={_internalContent ?? internalContent}
            templates={templates}
            schemas={schemas}
            contents={contents}
            navigate={navigate}
            id={_id}
            selectTpl={selectTpl}
            selected={selected}
            safeDisplay={safeDisplay}
            parentClick={
              parentClick ??
              (hasEventAction("onClick") ? (e) => onClick(e, true) : null)
            }
            schema_prop={schema_prop}
            setLocalContent={setLocalContent}
          />
        );
      }

    

      const {className,computedStyle,dynamicsProps } =getClassStyleAndDynamicsProps(template.isList ? index:null)

      if (template.actions) {

        template.actions.forEach((ea) => {
          //    window.alert("Actions " +ea.Event)
          dynamicsProps[ea.Event] = async (e) => {
            try {
              if (ea.Action.asyncrhone)
                await runAction(
                  e,
                  { ...ea.Action, self },
                  {..._content, navigate},
                  setInternal
                );
              else
                runActionSync(e, { ...ea.Action, self },   {..._content, navigate}, setInternal);
            } catch (e) {
              console.log("Error");
            }
          };
        });
      }
      if (CustomTag === "img" || CustomTag === "input") {

    
        
        return (
          <CustomTag
            ref={ref}
            key={index}
            type={template.type}
            id={
              (label
                ? label + (template.isList && index > 0 ? ":" + index : "")
                : null) ?? _id
            }
            className={className + "  "}
            style={{
              ...computedStyle,
            }}
            onClick={onClick}
            {...dynamicsProps}
          />
        );
      }

      return (
        <>
          <CustomTag
            ref={ref}
            key={index}
            id={
              (label
                ? label + (template.isList && index > 0 ? ":" + index : "")
                : null) ?? _id
            }
            className={className + " "}
            style={{
              ...computedStyle,

              ...(background
                ? {
                    backgroundImage: content[background]
                      ? `url(${content[background]})`
                      : `url(https://placehold.co/400x100/${Math.round(
                          1000000 * Math.random()
                        )}/DDDDDD/png?text=${background})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }
                : {}),
            }}
            {...dynamicsProps}
            onClick={onClick}
          >
            {(template.tag === "text" || template.schema_id===42 ||  template.schema_id===43 ||  template.schema_id===49 || template.schema_id===44  ) &&
           _getText(index)    
              }

            {"children" in template &&
              template.children.map((tpl, i) => (
                <TemplateX
                  template={tpl}
                  internals={internals}
                  palette={palette}
                  setInternal={_setInternal}
                  content={{
                    ..._content,

                    ...(element?.content ??
                      (typeof element === "object" ? element : {})),
                    ...(_internalContent ?? internalContent),
                    selfMap: template.schema_prop ?? template.schema_label ?? self,
                  }}
                  self={template.schema_prop ?? template.schema_label ?? self}
                  _internalContent={_internalContent ?? internalContent}
                  key={_id && _id + "." + i}
                  templates={templates}
                  schemas={schemas}
                  contents={contents}
                  navigate={navigate}
                  id={_id && _id + "." + i}
                  selectTpl={selectTpl}
                  selected={selected}
                  safeDisplay={safeDisplay}
                  parentClick={
                    parentClick ??
                    (hasEventAction("onClick") ? (e) => onClick(e, true) : null)
                  }
                  schema_prop={template.schema_prop ?? schema_prop}
                  setLocalContent={setLocalContent}
                />
              ))}
          </CustomTag>
          <PopupTemplate />
        </>
      );
    });

    //else
    //return <span >{endpoint} </span>
  }
);

export default TemplateX;
