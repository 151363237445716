import { data } from "../service/data";
import { getDataSource } from "../service/dataSource";
import { getRecord } from "../service/localdata";
import { retrieveProject } from "../service/mftc-api";
import { runActionSync } from "./actions";
import { metaStructureMapping } from "./meta";


function getConditions(template) {
  var conditions = [];

  if (template.condition) conditions.push(template.condition.split(" ")[0]);

  if (template.children)
    template.children.forEach((child) => {
      var cs = getConditions(child);
      cs.forEach((c) => {
        if (!conditions.find((c_) => c_ === c)) conditions.push(c);
      });
    });
  return conditions;
}

var createClass = (className, style, id) => {
  var sname = (id ? "#" + id : "") + "." + className;
  var S = document.getElementById(sname);
  if (!S) {
    S = document.createElement("style");
    S.id = sname;
    S.setAttribute("type", "text/css");
    document.body.appendChild(S);
  } else {
    S.removeChild(S.firstChild);
  }
  var cl1 = `${sname} { ${style}}   `;
  if (S.styleSheet) {
    S.styleSheet.cssText = cl1;
  } else {
    cl1 = document.createTextNode(cl1);
    S.appendChild(cl1);
  }
};

var deleteClass = (className, id) => {
  var sname = (id ? "#" + id : "") + "." + className;
  var S = document.getElementById(sname);
  if (S) {
    S.id = "";
    S.parentNode.removeChild(S);
  }
};

const contentConvert = (c) => {
  if (!c) return;
  var r = {
    content_id: c.content_id,
    template_id: c.template_id,
    schema_id: c.schema_id,
    ...c.content,
    Titre: c.content?.title,
  };
  console.log("contentConverting  !! ", { c, r });
  return r;
};

const arrayMerge = (a, b, id, merge = false) => {
  var c = [...(a ?? [])];
  c = c.map((el) => {
    var el1 = b.find(
      (el1) => el1 && el && ((id && el1[id] === el[id]) || (!id && el1 === el))
    );
    return el1 ? (merge ? { ...el, ...el1 } : el) : el;
  });

  c = [
    ...c,
    ...b.filter(
      (el) =>
        !c.find((el0) => (id && el0[id] === el[id]) || (!id && el0 === el))
    ),
  ];

  return c;
};

const loadAllContentAsync = async (content, contents, schemas, noSet) => {
  contents = contents ?? [];
  if (!contents.find((ct) => ct.content_id + "" === "" + content.content_id)) {
    contents.push(content);
  } else return contents;

  var schema = schemas.find(
    (s) => s.content_id + "" === "" + content.schema_id
  );

  var keys = Object.keys(content.content);
  for (var i = 0; i < keys.length; i++) {
    var prop = schema?.content.schema.find((p) => p.name === keys[i]);
    if (!prop) continue;

    var prop_schema = schemas.find(
      (s) => s.content_id + "" === "" + prop.schema_id
    );
    console.log("proploadAllContentAsync " + prop.name, { prop, prop_schema });
    if (!prop_schema || prop_schema.content.is_value) continue;

    if (prop.define) {
      continue;
    }

    if (prop_schema && prop_schema.content.entity) {
      let entity_name = prop_schema.content.entity;
      let ids = [];
      if (prop.type_nombre === "I" || prop.type_nombre === "F")
        ids = Array.isArray(content.content[keys[i]])
          ? content.content[keys[i]]
          : content.content[keys[i]]
          ? [content.content[keys[i]]]
          : [];
      else ids = content.content[keys[i]] ? [content.content[keys[i]]] : [];

      // Entity
      for (let id of ids) {
        if (typeof id === "object") id = id.content_id;
        id = ("" + id).split("_").pop();
        var content_id = entity_name + "_" + id;

        if (id && !contents.find((ct) => ct.content_id + "" === content_id)) {
          if (isNaN(id)) {
            console.log(" Error Id EnTITY ", { id, ids });
            continue;
          }
          var project = retrieveProject();
          var record = await project.api("/get_entity_record", {
            body: { entity: entity_name, id },
            cached: true,
          });

          if (!record.content_id)
            record = {
              content_id,
              title: record.title ?? record.label,
              content: record,
            };
          console.log("proploadAllContentAsync record " + prop.name, record);
          contents.push(record);
        }
      }
    } else {
      let ids = [];
      if (prop.type_nombre === "I" || prop.type_nombre === "F")
        ids = Array.isArray(content.content[keys[i]])
          ? content.content[keys[i]]
          : content.content[keys[i]]
          ? [content.content[keys[i]]]
          : [];
      else ids = content.content[keys[i]] ? [content.content[keys[i]]] : [];

      // Entity
      for (let c of ids) {
        let content_id = c.content_id;
        if (contents.find((ct) => ct.content_id + "" === "" + content_id))
          continue;

        let _c = await data.getContent(content_id, noSet);

        if (_c) {
          contents.push(_c);
          contents = await loadAllContentAsync(_c, contents, schemas, noSet);
        }
      }
    }
  }

  return contents;
};

const loadCompleteContentAsync = async (content) => {
  var keys = Object.keys(content.content);
  var _contents = { content_id: content.content_id, Titre: content.title };
  for (var i = 0; i < keys.length; i++) {
    if (
      typeof content.content[keys[i]] === "object" &&
      content.content[keys[i]]?.content_id
    ) {
      var _c = await data.getContent(content.content[keys[i]].content_id);
      //contents.find(c=>c.content_id=== parseInt( content.content[keys[i]].content_id) )

      if (_c) {
        _c = await loadCompleteContentAsync(_c);
        _contents[keys[i]] = _c;
        // Ajout des sous-proprietes
        var sub_keys = Object.keys(_c.content);
        sub_keys.forEach((sk) => {
          // Pour l'instant uniquement les objets
          if (typeof _c.content[sk] !== "object")
            _contents[keys[i] + "." + sk] = _c.content[sk];
        });
      }
    }
    if (Array.isArray(content.content[keys[i]])) {
      _contents[keys[i]] = content.content[keys[i]];
      for (var j = 0; j < content.content[keys[i]].length; j++) {
        var value = content.content[keys[i]][j];
        if (typeof value === "object" && value?.content_id) {
          var _c = await data.getContent(parseInt(value.content_id));

          if (_c) {
            _c = await loadCompleteContentAsync(_c);
            _contents[keys[i]][j] = _c;
          }
        }
      }
    }
  }

  return { ...content, content: { ...content.content, ..._contents } };
};

const loadCompleteContent = (content, contents) => {
  var keys = Object.keys(content.content);
  var _contents = { content_id: content.content_id, Titre: content.title };
  for (var i = 0; i < keys.length; i++) {
    if (
      typeof content.content[keys[i]] === "object" &&
      content.content[keys[i]]?.content_id
    ) {
      var _c = contents.find(
        (c) => c.content_id === parseInt(content.content[keys[i]].content_id)
      );

      if (_c) {
        _c = loadCompleteContent(_c, contents);
        _contents[keys[i]] = _c;
        // Ajout des sous-proprietes
        var sub_keys = Object.keys(_c.content);
        sub_keys.forEach((sk) => {
          // Pour l'instant uniquement les objets
          if (typeof _c.content[sk] !== "object")
            _contents[keys[i] + "." + sk] = _c.content[sk];
        });
      }
    }
    if (Array.isArray(content.content[keys[i]])) {
      _contents[keys[i]] = content.content[keys[i]];
      for (var j = 0; j < content.content[keys[i]].length; j++) {
        var value = content.content[keys[i]][j];
        if (typeof value === "object" && value?.content_id) {
          var _c = contents.find(
            (c) => c.content_id === parseInt(value.content_id)
          );

          if (_c) {
            _c = loadCompleteContent(_c, contents);
            _contents[keys[i]][j] = _c;
          }
        }
      }
    }
  }

  return { ...content, content: { ...content.content, ..._contents } };
};

const majContent = (content, updatedC) => {
  if (!content.content) return content;
  var keys = Object.keys(content.content);
  var _contents = { content_id: content.content_id, Titre: content.title };
  for (var i = 0; i < keys.length; i++) {
    if (
      typeof content.content[keys[i]] === "object" &&
      content.content[keys[i]]?.content_id
    ) {
      var _c = content.content[keys[i]];
      if (_c.content_id === updatedC.content_id) {
        _c = updatedC;
      } else if (_c) {
        _c = majContent(_c, updatedC);
        _contents[keys[i]] = _c;
        // Ajout des sous-proprietes
        var sub_keys = Object.keys(_c.content);
        sub_keys.forEach((sk) => {
          // Pour l'instant uniquement les objets
          if (typeof _c.content[sk] !== "object")
            _contents[keys[i] + "." + sk] = _c.content[sk];
        });
      }
    }
    if (Array.isArray(content.content[keys[i]])) {
      _contents[keys[i]] = content.content[keys[i]];
      for (var j = 0; j < content.content[keys[i]].length; j++) {
        var value = content.content[keys[i]][j];
        if (typeof value === "object" && value?.content_id) {
          var _c = value;

          if (_c.content_id === updatedC.content_id) {
            _c = updatedC;
          } else {
            _c = majContent(_c, updatedC);
            _contents[keys[i]][j] = _c;
          }
        }
      }
    }
  }

  return { ...content, content: { ...content.content, ..._contents } };
};

const structureReplace = (structure, dataToReplace, data_id) => {
  console.log("structureReplace", data_id, dataToReplace, structure);
  if (!data_id || data_id === "") return dataToReplace;
  var struct = JSON.parse(JSON.stringify({ ...structure }));
  var parts = data_id.split(".");
  var index = parseInt(parts.shift());

  struct.children[index] = structureReplace(
    struct.children[index],
    dataToReplace,
    parts.join()
  );
  return struct;
};

const structurePutIn = (structure, dataToPlace, pos) => {
  console.log("structurePutIn", pos, dataToPlace, structure);

  var struct = JSON.parse(JSON.stringify({ ...structure }));
  if ((!pos.data_id || pos.data_id === "") && pos.parent)
    return { ...structure, children: [...structure.children, dataToPlace] };

  var parts = pos.data_id.split(".");
  var index = parseInt(parts.shift());
  pos.data_id = parts.join();
  if (!pos.data_id || pos.data_id === "") {
    if (pos.sibling) {
      struct.children = [
        ...struct.children.filter((s, i) => i < index),
        dataToPlace,
        ...struct.children.filter((s, i) => i >= index),
      ];
      return struct;
    }
  }
  struct.children[index] = structurePutIn(
    struct.children[index],
    dataToPlace,
    pos
  );
  return struct;
};

const structureClean = (structure) => {
  structure.children = structure.children
    .filter((s) => !s.empty)
    .map((s) => structureClean(s));

  return structure;
};

async function loadTemplate(id, templates, project, noSet) {
  var _template = templates.find((t) => t.template_id === parseInt(id));
  if (!_template) _template = await data.getTemplate(parseInt(id), noSet);
  return _template;
}

const adaptedContents = async (
  content,
  cache,
  template,
  templates,
  project
) => {
  if (content.template_id + "" === "" + template.template_id) return [content];

  let baseTemplate = templates.find(
    (t) => t.template_id + "" === "" + content.template_id
  );
  let baseProps = await getProps(
    baseTemplate.structure,
    cache,
    project,
    baseTemplate.template_id
  );

  let destProps = await getProps(
    template.structure,
    cache,
    project,
    baseTemplate.template_id
  );

  for (let i = 0; i < baseProps.length; i++) {
    baseProps[i].fitProps = [];
    for (let j = 0; j < destProps.length; j++) {
      //TODO verifier si ce sont des listes ou pas pour optimiser la compatibilité
      if (baseProps[i].schema_id === destProps[j].schema_id) {
        baseProps[i].fitProps.push(j);
      }
    }
  }

  let contents = [];

  for (let i = 0; i < baseProps.length; i++) {
    contents.push({
      ...content,
      content: {},
      nFit: 0,
      nTotal: baseProps.length,
    });
    let new_contents = [];

    for (let j = 0; j < baseProps[i].fitProps.length; j++) {
      for (let k = 0; k < contents.length; k++) {
        var _content = JSON.parse(JSON.stringify({ ...contents[i] }));
        if (!(destProps[baseProps[i].fitProps[j]].name in _content.content)) {
          _content.content[destProps[baseProps[i].fitProps[j]].name] =
            content.content[baseProps[i].name];
          _content.nFit++;
        }
        new_contents.push(_content);
      }
    }
    contents = new_contents;
  }

  return contents.filter((c) => c.nFit > 0);
};

const getParamsFromStructure = (structure,pref="",labelProps=null) => {
  var params = { template_id: {}, props: {}, schema_id: {} };
  if (structure) {
     if (structure.schema_prop) {
      params.props[structure.schema_prop] = {
        name: structure.schema_prop,
        from:labelProps && labelProps?.find(p=>p.name ===structure.schema_prop) ?   "schema_label":"self",
        type_nombre: structure.isList ? "I" : null,
        schema_id: structure.schema_id,
      };
    }
    else
    if (structure.schema_label) {
      if (!structure.schema_id) {
        switch (structure.tag) {
          case "text":
            structure.schema_id = 42;
            structure.defaultValue = structure.text;
            break;
          case "img":
            structure.schema_id = 50;
            structure.defaultValue = structure.imageUrl;
            break;
          default:
        }
      }

      params.props[structure.schema_label] = {
        name: structure.schema_label,
        from: "schema_label",
        type_nombre: structure.isList ? "I" : null,
        schema_id: structure.schema_id,
      };
    }
 
    if (structure.template_id) {
      if(structure.is_meta)
        params.template_id[structure.template_id+"-"+structure.suid] = {...structure};
        else
      params.template_id[structure.template_id] = true;
    }
    if (structure.schema_id) {
      params.schema_id[structure.schema_id] = true;
    }
    if(structure.schema_id && !structure.schema_prop && !structure.schema_label)
      {

        params.props[structure.suid] = {
          name: pref  + structure.suid,
          from: "schema_suid",
          type_nombre: structure.isList ? "I" : null,
          schema_id: structure.schema_id,
        };

      }
    if (structure.action_click) {
      structure.actions = [
        ...(structure.actions ?? []),
        { Event: "onClick", Action: structure.action_click },
      ];
    }
    if (structure.actions) {
      //// Ajout des paramètres d'actions non rempli
      for (var ea of structure.actions) {
        let action = ea.Action;
        if (!action) continue;
        Object.keys(action.Arguments).forEach((key) => {
          var keyContent = action.Arguments[key].value ?? key;

          if (!params.props[keyContent])
            params.props[keyContent] = {
              name: keyContent,
              from: "schema_action",
              type_nombre: action.Arguments[key].isList ? "I" : null,
              schema_id: action.Arguments[key].schema_id,
            };
        });
      }
    }
    if (structure.computed) {
      //// Ajout des paramètres d'actions non rempli
      for (var action of structure.computed) {
        if (!action) continue;
        Object.keys(action.Arguments).forEach((key) => {
          var keyContent = action.Arguments[key].value ?? key;

          if (!params.props[keyContent])
            params.props[keyContent] = {
              name: keyContent,
              from: "schema_action",
              type_nombre: action.Arguments[key].isList ? "I" : null,
              schema_id: action.Arguments[key].schema_id,
            };
        });
        if (!action.Sortie) continue;

        let keyOutput = action.Sortie.value ?? action.Sortie.name;
        if (!params.props[keyOutput]    || !params.props[keyOutput].computed )
          params.props[keyOutput] = {
            ...action.Sortie,
            from: "schema_computed",
            type_nombre: action.Sortie.isList ? "I" : null,
            computed: action,
          };
      }
    }

    if (structure.schema_url) {
      if (!params.props[structure.schema_url])
        params.props[structure.schema_url] = {
          name: structure.schema_url,
          from: "schema_url",
          type_nombre: null,
          schema_id: 466,
        };
    }

    if (structure.schema_link) {
      if (!params.props[structure.schema_link])
        params.props[structure.schema_link] = {
          name: structure.schema_link,
          from: "schema_link",
          type_nombre: null,
          schema_id: 46,
        };
    }

    if (structure.schema_click) {
      if (!params.props[structure.schema_click])
        params.props[structure.schema_click] = {
          name: structure.schema_click,
          from: "schema_click",
          type_nombre: null,
          schema_id: 43,
        };
    }

    structure.children?.forEach((s) => {
      var p = getParamsFromStructure(s,"",labelProps);
      params = {
        template_id: { ...p.template_id, ...params.template_id },
        props: { ...p.props, ...params.props },

        schema_id: { ...params.schema_id, ...p.schema_id },
      };
    });
  }
  return params;
};

const getPropsFromTemplates = async (
  template_id,
  cache,
  templates,
  project,
  params,
  meta_structure,
  rtemplate_id
) => {
  params = params ?? {};
  rtemplate_id=rtemplate_id ?? template_id
  if (template_id) {
    if (params[rtemplate_id]) return params;

    var _template = await loadTemplate(template_id, templates, project);


var structure =_template.structure
if(meta_structure) structure = metaStructureMapping(structure,meta_structure)
    params[rtemplate_id] = getParamsFromStructure(structure,"", Object.values(   _template.props ?? {}) );

    if (_template.schema_id) {
      params[rtemplate_id].props["self"] = {
        name: "self",
        from: "",
        type_nombre: _template.layout === "list" ? "I" : null,
        schema_id: _template.schema_id,
      };
    }

    for (let _template_id of Object.keys(params[rtemplate_id].template_id)) {
      let _meta_structure 
      if (!params[_template_id])
      {  
       if( params[rtemplate_id].template_id[_template_id]===true)

{

        params = await getPropsFromTemplates(
          _template_id,
          cache,
          templates,
          project,
          params
        );
      }
else
{
      var [_rTemplate_id,suid ] = _template_id.split("-")
      _meta_structure =  params[rtemplate_id].template_id[_template_id]
      params = await getPropsFromTemplates(
        _rTemplate_id   ,
        cache,
        templates,
        project,
        params,
        params[rtemplate_id].template_id[_template_id],
        _template_id
      );


}

      }

      for (let prop_label of Object.values(params[_template_id].props).filter(
        (p) => p.from === "schema_label"
      )) {
        var pref=""
        if(_meta_structure) pref=_template_id.split("-")[1]+"."
        // ICI ON AJOUTE LE PREFIXE  DU SUID si on VEUT !!!
        params[rtemplate_id].props[  pref +  prop_label.name] = prop_label;
        console.log(" ajout prop subtemplate :" + prop_label.name, prop_label);
      }
    }
    console.log(" ajout prop subtemplate :" + template_id, {
      params: params[rtemplate_id],
      template: _template.structure,
    });
  }

  return params;
};

const getProps = async (tpl, cache, templates, project, id, noSet,pref="") => {
  let props = [];
  const cache_key = "template_props_" + id;
  if (false && id) {
    props = cache.get(cache_key);
    if (props) return props;
  }

  if (tpl) {
    //Text
    //Boolean
    //Number
    //Image
    //Video
    //Media
    //Template
    //Objet
    if (tpl.template_id) {
      var _template = await loadTemplate(tpl.template_id, templates, project);
      var subProps = await getProps(
        _template.structure,
        cache,
        templates,
        project
      );
      props = arrayMerge(props, subProps, "name", true);
    }
    if (tpl.schema_label) {
      if (!tpl.schema_id) {
        switch (tpl.tag) {
          case "text":
            tpl.schema_id = 42;
            tpl.defaultValue = tpl.text;
            break;
          case "img":
            tpl.schema_id = 50;
            tpl.defaultValue = tpl.imageUrl;
            break;
          default:
        }
      }
      if (tpl.isList) {
        tpl.type_nombre = "F";
      }

      props = arrayMerge(
        props,
        [{ name: tpl.schema_label, ...{ ...tpl, children: null } }],
        "name",
        true
      );
    }
    else if(tpl.schema_id)
      {
        if (tpl.isList) {
          tpl.type_nombre = "F";
        }

        props = arrayMerge(
          props,
          [{ name:  pref+  tpl.suid ,    ...{ ...tpl, children: null } }],
          "name",
          true
        );

        

      }
    if (tpl.action_click) {
      //// Ajout des paramètres d'actions non rempli
      let action = tpl.action_click;

      Object.keys(action.Arguments).forEach((key) => {
        var keyContent = action.Arguments[key].value;
        if (!keyContent)
          props = arrayMerge(props, [action.Arguments[key]], "name", true);
      });
    }

    if (tpl.actions) {
      //// Ajout des paramètres d'actions non rempli
      for (var ea of tpl.actions) {
        let action = ea.Action;
        if (!action) continue;
        Object.keys(action.Arguments).forEach((key) => {
          var keyContent = action.Arguments[key].value;
          if (!keyContent)
            props = arrayMerge(props, [action.Arguments[key]], "name", true);
        });
      }
    }
    if (tpl.schema_url) {
      props = arrayMerge(
        props,
        [{ name: tpl.schema_url, schema_id: 466 }],
        "name",
        true
      );
    }

    if (tpl.schema_link) {
      props = arrayMerge(
        props,
        [{ name: tpl.schema_link, schema_id: 46 }],
        "name",
        true
      );
    }

    if (tpl.schema_click) {
      props = arrayMerge(
        props,
        [{ name: tpl.schema_click, schema_id: 43 }],
        "name",
        true
      );
    }
    //Liste
  }
  if (tpl.children && tpl.children.length > 0) {
    for (var i = 0; i < tpl.children.length; i++) {
      var _tpl = tpl.children[i];
      let subProps = await getProps(
        _tpl,
        cache,
        templates,
        project,
        null,
        noSet,pref 
      );

      props = arrayMerge(props, subProps, "name", true);
    }
  }

  if (id) console.log("getProps of Template", { props, tpl });
  if (id) cache.set(cache_key, props);
  return props;
};

function getPageTemplates(page, templates) {
  var result = [];
  if (
    page.content.header &&
    parseInt("0" + page.content.headerContent_id) > 0
  ) {
    let template = templates.find(
      (t) => t.template_id + "" === "" + page.content.header
    );
    if (template) result.push(template);
  }
  if (page.content.body)
    for (let i = 0; i < page.content.body.length; i++) {
      if (page.content.body[i].template_id) {
        let template = templates.find(
          (t) => t.template_id + "" === "" + page.content.body[i].template_id
        );
        if (template) result.push(template);
      }
    }
  if (page.content.footer && parseInt("0" + page.content.footer) > 0) {
    let template = templates.find(
      (t) => t.template_id + "" === "" + page.content.footer
    );
    if (template) result.push(template);
  }
  return result;
}

async function getContentActivated(
  content,
  content_id,
  baseContent,
  project,
  setInternal,
  contents
) {
  return;
}

const activateContent = async (
  page,
  baseContent,
  project,
  setInternal,
  setIsReady,
  setContents,
  contents
) => {

  if (!page) return;
  var schemas = await data.getSchemas();
  var contents_id = [];
  if (
    page.content.headerContent_id &&
    parseInt("0" + page.content.headerContent_id) > 0
  )
    contents_id.push(page.content.headerContent_id);
  for (let i = 0; i < page.content.body.length; i++) {
    console.log("page.content.body", page.content.body[i]);
    if (
      page.content.body &&
      page.content.body[i] &&
      page.content.body[i].content_id &&
      parseInt("0" + page.content.body[i].content_id) > 0
    ) {
      contents_id.push(page.content.body[i].content_id);
    }
  }
  if (
    page.content.footerContent_id &&
    parseInt("0" + page.content.footerContent_id) > 0
  )
    contents_id.push(page.content.footerContent_id);

  var completecontents = await data.getCompleteContents(
    contents_id,
    contents,
    schemas
  );

  for (let i = 0; i < completecontents.length; i++) {
    var content_id = parseInt(completecontents[i].content_id);
    console.log("content_id", content_id);
    await getContentActivated(
      completecontents[i],
      content_id,
      baseContent,
      project,
      setInternal,
      contents
    );
    // setContents(_contents)
  }
  if (setContents)
    setContents(arrayMerge(contents, completecontents, "content_id"));
  console.log("Ready Achieve  ", completecontents);
  if (setIsReady) setIsReady(true);
};

const updateStructure = (structure, props, id,replaceStructure) => {
  id = id.replace("-root.", "").replace("-root", "");
  console.log("updateStructure", structure, props, id);
  if (id === "") {
    if (props.children && props.tag) return props;
    return replaceStructure ? props : { ...structure, ...props };
  }
  var parts = id.split(".");
  var val = parts.shift();
  var i = parseInt(val);

  return {
    ...structure,
    children: structure.children.map((structureChild, _i) => {
      if (_i === i)
        return updateStructure(structureChild, props, parts.join("."),replaceStructure);
      return structureChild;
    }),
  };
};
const getStructure = (structure, id) => {
  if ((!id && id !== "") || !structure) return { className: "", children: [] };
  id = id.replace("-root.", "").replace("-root", "");
  if (id === "") return structure;

  var parts = id.split(".");
  var i = parts.shift();
  i = parseInt(i);

  return getStructure(structure.children[i], parts.join("."));
};
const getIdStructure = (structure, id, idStr="") => {
  
  if ((!id && id !== "") || !structure) return idStr;

  if(id.startsWith("-root")) idStr="-root";
  id = id.replace("-root.", "").replace("-root", "");

  if (id === "") return idStr;


  var parts = id.split(".");
  var i = parts.shift();
  if(!structure.children[i]) return idStr;
  idStr +=(idStr!==""?".":"") + i
  i = parseInt(i);

  return getIdStructure(structure.children[i], parts.join("."),idStr);
};
const FlatObject = (object, prefix = "") => {
  if (!object) return;
  if (typeof object !== "object") return object;
  var _object = {};
  Object.keys(object).forEach((key) => {
    if (
      object[key] &&
      typeof object[key] === "object" &&
      !Array.isArray(object[key])
    ) {
      var flatobj = FlatObject(object[key], prefix + key + ".");
      _object = { ...object, ...flatobj };
      if ((prefix + key).startsWith("content."))
        _object[("*" + prefix + key).replace("*content.", "")] = object[key];
      else _object[(prefix + key).replace(".content.", ".")] = object[key];
    } else {
      if ((prefix + key).startsWith("content."))
        _object[("*" + prefix + key).replace("*content.", "")] = object[key];
      else _object[(prefix + key).replace(".content.", ".")] = object[key];
    }
  });
  // console.log("Flatted Object :", object ,"to",_object)
  return _object;
};

const setMixedKeyValue = (obj, mixedkey, value) => {
  var parts = mixedkey.split(".");
  if (parts.length === 1) obj[mixedkey] = value;
  else {
    var key = parts.shift();
    if (!obj[key]) obj[key] = {};
    setMixedKeyValue(obj[key], parts.join("."), value);
  }
};

const RecomposeContent = (displayContent) => {
  var content = {
    content_id: displayContent["content_id"],
    domain: displayContent["App.domain"],
    app_id: displayContent["App.app_id"],

    content: {},
  };

  var keys = Object.keys(displayContent);
  keys.forEach((key) => {
    if (key.indexOf("_input_prop") > 0) {
      //content["prop_" + key] = displayContent[key]
      var prop = displayContent[key];
      if (prop.parent_schema_id) content.schema_id = prop.parent_schema_id;
      var realname = key.replace("_input_prop", "");
      if (
        typeof displayContent[realname] === "object" &&
        displayContent[realname].content_id
      )
        setMixedKeyValue(content.content, realname, {
          content_id: displayContent[realname].content_id,
        });
      //   content.content[prop.name] = {  content_id: displayContent[prop.name].content_id,  };
      else
        setMixedKeyValue(content.content, realname, displayContent[realname]);
      //content.content[prop.name] = displayContent[prop.name];
      //    content.schema[key] = prop;
    }
  });
  console.log("displayContent !!!", displayContent);
  console.log("result content !!!", content);
  if (!content.app_id) {
    content.app_id = displayContent.app_id;
  }
  if (!content.schema_id) {
  }
  // window.alert("Content Recompose " + JSON.stringify(content));
  return content;
};
const getInputsTemplate = (structure, templates, schema_id) => {
  var result = [];

  if (structure.tag === "input" && structure.schema_prop)
    result.push({
      schema_prop: structure.schema_prop,
      template_id: structure.template_id,
      schema_id,
    });
  for (var i = 0; structure.children && i < structure.children.length; i++) {
    result = [
      ...result,
      ...getInputsTemplate(structure.children[i], templates, schema_id),
    ];
  }
  if (structure.template_id) {
    var _template = templates.find(
      (t) => t.template_id + "" === "" + structure.template_id
    );
    if (_template) {
      var inputsT = getInputsTemplate(
        _template.structure,
        templates,
        _template.schema_id
      );

      result = [
        ...result,
        ...inputsT.map((p) => ({
          schema_prop: p.schema_prop,
          template_id: p.template_id,
          schema_id: p.schema_id,
        })),
      ];
    }
  }
  // if (result.length > 0) console.log("getInputsTemplate testing", structure);
  return result;
};

const setPropInputs = (template, schemas, templates = []) => {
  // console.log("setPropInputs", { template, schemas, templates });
  var subContent = {};

  if (template && schemas) {
    var schema_props = getInputsTemplate(
      template.structure,
      templates,
      template.schema_id
    );
    console.log("getInputsTemplate ", { template, schema_props });
    if (template.schema_id && !subContent["___D"])
      subContent["___D"] = {
        schema_id: template.schema_id,
        label: schemas.find(
          (s) => s.content_id === parseInt(template.schema_id)
        )?.title,
      };
    for (var settings of schema_props) {
      var schema_prop = settings.schema_prop;
      console.log("setPropInputs settings", settings);
      var prop = getPropSchema(schema_prop, settings.schema_id, schemas);
      prop.parent_schema_id = settings.schema_id;
      if (settings.template_id) prop.template_id = settings.template_id;
      subContent[schema_prop + "_input_prop"] = prop;
      subContent[schema_prop + "_input_schema"] = schemas.find(
        (s) => s.content_id === parseInt(prop.schema_id)
      );
      if (template.schema_id && !subContent["CRUD"])
        subContent["CRUD"] = {
          schema_id: template.schema_id,
          label: schemas.find(
            (s) => s.content_id === parseInt(template.schema_id)
          )?.title,
        };
    }
    if (schema_props.length > 0)
      console.log("setPropInputs subContent", subContent);
    if (schemas.length > 0 && template)
      console.log("setPropInputs test subContent", { schemas, template });
  }

  return subContent;
};

const getPropSchema = (schema_prop, schema_id, schemas) => {
  var result = { name: schema_prop };

  if (schemas) {
    var schema = schemas.find((s) => s.content_id === parseInt(schema_id));
    window.console.log("getPropSchema INPUT ", {
      schema,
      schema_prop,
      schema_id,
      schemas,
    });
    var parts = schema_prop.split(".");
    var root_schema_prop = parts.shift();
    if (schema) {
      if (schema.content?.schema) {
        var prop = schema.content.schema.find(
          (p) => p.name === root_schema_prop
        );

        if (prop) {
          if (parts.length === 0) return prop;
          else if (prop.schema_id)
            return getPropSchema(parts.join("."), prop.schema_id, schemas);
        }
      }
    }
  }
  return result;
};




const props_from_template = async(template,templates)=>{


  var _propsFromTemplates = await getPropsFromTemplates(
    template.template_id,
    null,
    templates,
    retrieveProject()
  );

  for (let prop of Object.values(
    _propsFromTemplates[template.template_id].props
  )) {


    if (prop.from==="schema_label") {
      if (!template.props || !Array.isArray(template.props ))template.props =[]
      if(!template.props.find(p=>p.name===prop.name))
      template.props.push({...prop,type_nombre: prop.type_nombre ?? ( prop.isList? "I":null)})

    }

  }

    if(template.props && Array.isArray(template.props ))
       template.props.forEach(prop=>{

      _propsFromTemplates[template.template_id].props[prop.name]= {..._propsFromTemplates[template.template_id].props[prop.name]??{} , ...prop,from:"schema_label"}
    })


    _propsFromTemplates[template.template_id].props["Titre"]= {"name":"Titre",schema_id:42 ,from:"self"}
    _propsFromTemplates[template.template_id].props["title"]= {"name":"title",schema_id:42 ,from:"self"}


    return _propsFromTemplates;
}
function customLongHash(text, pref = "") {
  let hash1 = 0x12345678;
  let hash2 = 0x9abcdef0;

  if (text.length === 0) return hash1.toString(16) + hash2.toString(16);

  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i);
    hash1 = (hash1 << 5) - hash1 + char;
    hash1 |= 0; // Convert to 32bit integer

    hash2 = (hash2 << 7) - hash2 + char;
    hash2 |= 0; // Convert to 32bit integer
  }

  // Combine the two hashes to create a longer hash string
  const combinedHash = (hash1.toString(16) + hash2.toString(16)).padStart(
    16,
    "0"
  );
  return pref + combinedHash;
}

const getContentObject = (c, schema, contents) => {
  let content_id;
  if (!c?.content_id && schema?.content.entity) {
    if (!c) return;
    //console.log( "searchEntity " + schema?.content.entity +" " + c , contents)

    if (typeof c === "number") content_id = schema?.content.entity + "_" + c;
    if (typeof c === "string")
      content_id = schema?.content.entity + "_" + c.split("_").pop();
  } else if (c && c.content_id) content_id = c.content_id;

  if (!content_id) return;
  var obj = { content_id: content_id };

  var content = data.getLoadedContent(content_id);

  if (!content)
    content = contents.find((_c) => _c.content_id + "" === "" + content_id);

  if (!content && c.content) {
    return { content_id: c.content_id, ...c.content };
  }
  if (!content) return obj;

  obj.title = content.content.title;

  if (schema) {
    for (let prop of schema.content.schema) {
      if (prop.name in content.content)
        obj[prop.name] = content.content[prop.name];
    }
  } else obj = { ...obj, content: null, ...content.content };

  //console.log("getContentObject Result", { c, schema, obj });
  return obj;
};

const setContentObject = async (c, schema, contents) => {
  if (!c || !c.content_id) return {};

  var content = data.getLoadedContent(c.content_id);
  if (!content)
    content = contents.find((_c) => _c.content_id + "" === "" + c.content_id);

  console.log("try set content " + c.content_id, { c, content, contents });

  if (!content) return;
  if (schema) {
    for (let prop of schema.content.schema) {
      if (prop.name in c) content.content[prop.name] = c[prop.name];
    }
    if (c.title) {
      content.content.title = c.title;
      content.title = c.title;
    }
  } else content = { ...content, content: { ...content.content, ...c } };

  await data.updateContent(content);
  return content;
};




const postContentObject = async (c, schema, contents) => {
  if (!c || !c.content_id) return {};

  var content = data.getLoadedContent(c.content_id);
  if (!content)
    content = contents.find((_c) => _c.content_id + "" === "" + c.content_id);
  console.log("try set content " + c.content_id, { c, content, contents });

  if (c.content_id === "new") {
    content = {
      schema_id: schema.content_id,
      app_id: schema.app_id,
      content: {},
    };
  }

  if (!content) return;
  if (schema) {
    for (let prop of schema.content.schema) {
      if(prop.front) continue
      if (prop.name in c) {
        if (typeof c[prop.name] === "object" && c[prop.name].content_id)
          c[prop.name] = { content_id: c[prop.name].content_id };
        if (Array.isArray(c[prop.name]))
          c[prop.name] = c[prop.name].map((val) =>
            typeof val === "object" && val.content_id
              ? { content_id: val.content_id }
              : val
          );

        content.content[prop.name] = c[prop.name];
      }
    }

    if (c.title) {
      content.content.title = c.title;
      content.title = c.title;
    }
  } else content = { ...content, content: { ...content.content, ...c } };

  //  console.log({content})
  //  window.alert("Go to console  and see content")

  //if(!window.confirm("Save ??")) return

  content = await data.save_content(content);
  return content;
};

const _GetSetTers = (
  template,
  getSelf,
  setSelf,
  content_id,
  contents,
  propsFromTemplates,
  schemas,
  infos
) => {

  if (
    !propsFromTemplates ||
    !template ||
    !template.template_id ||
    schemas.length === 0 ||
    !content_id
  )
    return {};
    if(infos?.element_id)
      console.log("Trying Getters Setters Functions for " + infos?.element_id + " " + template.title);
    
  var getContent = {
    do: () => getContentObject({ content_id }, null, contents),
    stack: ["get content_id = " + content_id],
  };

  var setContent = {
    do: async (value) => await setContentObject(value, null, contents),
    stack: ["set content_id = " + content_id],
  };

  if (!getSelf && template.schema_id) {
    let schema = schemas.find(
      (s) => s.content_id + "" === "" + template.schema_id
    );
    let is_value = schema.content.is_value;

    if (is_value) {
      getSelf = {
        do: () => getContent.do().self,
        stack: [...getContent.stack, " get self value"],
      };
      if (!setSelf)
        setSelf = {
          do:async (value) => await setContent.do({ ...getContent.do(), self: value }),
          stack: [...setContent.stack, " get self value"],
        };
    } else {
      getSelf = {
        do: () => {

          return getContentObject(getContent.do().self, schema, contents);
        },
        stack: [...getContent.stack, "get self object"],
      };

      if (!setSelf)
        setSelf = {



          do:async (value) =>{ 
             var  old_value = getSelf.do()
              if(old_value?.content_id !==value?.content_id )
                {
                console.log( " //on change la reference du self" )
                return  await setContent.do({ ...getContent.do(), self: value })
                } 
                else
                {
                  console.log( " // on change le contenu du self" ,{old_value,value}  )
             
                  let result = await setContentObject(value, schema, contents);
                  return result;
                }
            
          },
          stack: [...setContent.stack, " get self object"],
        };
       /* setSelf = {
          do: (  async (value) =>
         { 
          
       console.log("Old SELF content" ,{ OLD :  getSelf.do() ,new :value    , actual :  { ...getSelf.do(), ...value } }  )
          
          await setContentObject(
              { ...getSelf.do(), ...value },
              schema,
              contents
            )    
          
        }),
          stack: [...setContent.stack, "get self object"],
        };   */
    }
  }
  else if( !getSelf){
    setSelf = {
      do:async (value) =>null,
      stack: [ " get no self value"],
    };
    getSelf = {
      do: () => {

        return null;
      },
      stack: [..."get no self"],
    };
  }

  var params = propsFromTemplates[template.template_id];
  if (!params) {
    
    if(infos?.element_id)
      console.log("Error No Params  Getters Setters Functions for " + infos?.element_id + " " + template.title + " " + template.template_id,propsFromTemplates);
  
    return {};
   } var funcs = {};

  var props = params.props;

  if (template.schema_id) {
    let schema =
      (props.self &&
        props.self.schema_id + "" === "" + template.schema_id &&
        props.self.schema) ??
      schemas.find((s) => s.content_id + "" === "" + template.schema_id);
    if (!schema) {
      console.log(" Schema non trouvé pour " + template.title, {
        template,
        props,
        propsFromTemplates,
        schemas,
      });

    if(infos?.element_id)
      console.log("Error Schema Not FOund  Getters Setters Functions for " + infos?.element_id + " " + template.title);
  
      return {};
    }
    schema.content?.schema?.forEach((prop) => {
      props[prop.name] = { ...prop, from: "self" };
    });
    if(! schema.content?.is_value)
    props["Titre"] = {
      name: "Titre",
      from: "self",
      realName: "title",
      schema_id: 42,
      code: "text",
    };
  }

  var computedProps = [];
  for (let prop of Object.values(props)) {
    prop.schema = schemas.find(
      (s) => s.content_id + "" === "" + prop.schema_id
    );
    if (!prop.schema) continue;
    let is_value = prop.schema.content.is_value;
    let is_list = prop.type_nombre === "I" || prop.type_nombre === "F";
    let is_define = prop.define;

    if (prop.computed) {
      computedProps.push(prop);

      //  params.props[keyOutput]=  {  ...action.Sortie,from: "schema_computed", type_nombre: action.Sortie.isList? "I":null , computed :action  }
      continue;
      //On le fait à la fin pour receuillir toutes les functions
    }

    if (prop.name === "self") {
      funcs["_get_name_" + prop.name] = {
        do: () => {
          return prop.name;
        },
        stack: [...getSelf?.stack, "get Name of prop.name =" + prop.name],
      };

      if (is_list)
       { funcs["_get_count_" + prop.name] = {
          do: () => {
            return prop.name;
          },
          stack: [...getSelf.stack, "get Name of prop.name =" + prop.name],
        };
      }
      funcs["_get_" + prop.name] = {
        do: () => {
          var value = getSelf.do();
          return value;
        },
        stack: [...getSelf.stack, "get prop.name =" + prop.name],
      };
      funcs["_set_" + prop.name] = {
        do: setSelf.do,
        stack: [...setSelf.stack, "set prop.name =" + prop.name],
      };

      if (!is_value) {


        funcs["_open_" + prop.name] = {
          do: async (navigate,index,slug) => {
            //   if(!window.confirm("Save " +  prop.name +" (2) ??")) return
            var object = funcs["_get_" + prop.name].do(index)

            if (object?.content_id) {
              if(!slug)
                {
                  // chercher pages trouver quelle est celle qui ouvre un objet avec 
                 
                   var result  = await retrieveProject().api("/get_data_slug/:app_id/:schema_id", {args:{app_id:infos.app_id, schema_id: prop.schema_id }})

               
                   slug = result?.slug
                   var parts= window.location.pathname.split("/")
                   if(slug && parts[1]==="domain")
                    slug = "/domain/"+ parts[2]+"/"+slug 
                 
                }
                  if(slug) 
                    {
                      slug= slug.split("/").map(s=> s.startsWith(":") ?  object?.content_id :s  ).join("/")
                    }
                   navigate(slug)
            }
          },
          stack: [
            ...getSelf.stack,
            " open " +
              prop.name +
              " object  from " +
              prop.from +
              " " +
              (prop.type_nombre === "I" || prop.type_nombre === "F"
                ? "[List]"
                : ""),
          ],
        };


        funcs["_popup_" + prop.name] = {
          do: async (index,template_id) => {
            //   if(!window.confirm("Save " +  prop.name +" (2) ??")) return
            var object = funcs["_get_" + prop.name].do(index)
        
            if (object?.content_id) {
           
                  // chercher pages trouver quelle est celle qui ouvre un objet avec 
                   var popup_template = await retrieveProject().api("/get_data_popup", {body:{template_id,app_id:infos.app_id, schema_id: prop.schema_id  }})
                   // ouvrir une modale  
        
         
            }
          },
          stack: [
            ...getSelf.stack,
            " popup " +
              prop.name +
              " object  from " +
              prop.from +
              " " +
              (prop.type_nombre === "I" || prop.type_nombre === "F"
                ? "[List]"
                : ""),
          ],
        };
        funcs["_post_" + prop.name] = {
          do: async (value) => {
            //   if(!window.confirm("Save "+ prop.name +" ??")) return
            await postContentObject(value, prop.schema, contents);
          },
          stack: [...setSelf.stack, "post  prop.name =" + prop.name],
        };
      }

      //Fin du self
    } else {
      let getFunction = prop.from === "self" ? getSelf : getContent;
      let setFunction = prop.from === "self" ? setSelf : setContent;

      if(!getFunction)

{
console.log("Error No GetFunction for " + prop.name ,)
continue
}   

funcs["_get_name_" + prop.name] = {
        do: () => {
          return prop.name;
        },
        stack: [...(getFunction?.stack ?? []), "get Name of prop.name =" + prop.name],
      };

      if (is_list)
        funcs["_get_count_" + prop.name] = {
          do: () => {
            var self = getFunction.do();

            return self ? (self[prop.realName ?? prop.name] ?? []).length : 0;
          },
          stack: [...(getFunction?.stack ?? []), "get Count of prop.name =" + prop.name],
        };

      if (is_value) {
        funcs["_get_" + prop.name] = {
          do: (index) => {
            //    console.log("get_Prop " + template.title + " " + prop.name )

            var self = getFunction.do();

            var value = self ? self[prop.realName ?? prop.name] : null;

            if (is_list) {
              value = value ? (Array.isArray(value) ? value : [value]) : [];

              if (prop.schema.content.code === "numeric")
                value = value.map((v) => parseFloat(v));
            } else {
              if (prop.schema.content.code === "numeric")
                value = value ? parseFloat(value) : value;
            }

            value = typeof index === "number" ? value[index] : value;
            //    console.log("final_value_get_ self" + prop.name, { self, value });
            return value;
          },
          stack: [
            ...getFunction.stack,
            " get " +
              prop.name +
              " value from " +
              prop.from +
              " " +
              (prop.type_nombre === "I" || prop.type_nombre === "F"
                ? "[List]"
                : "") +
              (prop.schema.content.code === "numeric" ? " numeric " : ""),
          ],
        };

        funcs["_set_" + prop.name] = {
          do: (value, index, action) => {
            //     console.log("value _get_set_" + prop.name, value);

            var self = getFunction.do();

            var _value;
            if (is_list && typeof index === "number") {
              _value = self[prop.realName ?? prop.name];
              switch (action) {
                case "insert":
                  _value = [
                    ...(_value ?? []).filter((v, i) => i < index),
                    value,
                    ...(_value ?? []).filter((v, i) => i >= index),
                  ];
                  break;
                case "delete":
                  _value = (_value ?? []).filter((v, i) => i !== index);
                  break;
                default:
                  _value[index] = value;
              }
            } else {
              //change toute la liste
              _value = value;
            }

            setFunction.do({
              ...getFunction.do(),
              [prop.realName ?? prop.name]: value,
            });
          },
          stack: [
            ...setFunction.stack,
            " set " +
              prop.name +
              " value from " +
              prop.from +
              " " +
              (prop.type_nombre === "I" || prop.type_nombre === "F"
                ? "[List]"
                : "") +
              (prop.schema.content.code === "numeric" ? " numeric " : ""),
          ],
        };
      } else {
        funcs["_get_" + prop.name] = {
          do: (index) => {
            var self = getFunction.do();

            var value = self ? self[prop.realName ?? prop.name] : null;
            //     console.log("_get_" + prop.name + " " + prop.from, { self, value });
            if (is_list) {
              value = value ? (Array.isArray(value) ? value : [value]) : [];

              if (!is_define)
                value = value.map((v) =>
                  getContentObject(v, prop.schema, contents)
                );
            } else {
              if (!is_define)
                value = getContentObject(value, prop.schema, contents);
            }

            return typeof index === "number" && Array.isArray(value) ? value[index] : value;
          },
          stack: [
            ...getFunction.stack,
            " get " +
              prop.name +
              " object  from " +
              prop.from +
              " " +
              (prop.type_nombre === "I" || prop.type_nombre === "F"
                ? "[List]"
                : "") +
              (prop.schema.content.code === "numeric" ? " numeric " : ""),
          ],
        };




        funcs["_set_" + prop.name] = {
          do: async (value, index, action) => {
            console.log(
              "object _set_" + prop.name + " " + prop.from,
              value
            );

            var self = getFunction.do();
            var _value = self[prop.realName ?? prop.name];
            var changeContent = false;
            if (is_list && typeof index === "number") {
              switch (action) {
                case "insert":
                  _value = [
                    ...(_value ?? []).filter((v, i) => i < index),
                    value,
                    ...(_value ?? []).filter((v, i) => i >= index),
                  ];
                  break;
                case "delete":
                  _value = (_value ?? []).filter((v, i) => i !== index);
                  break;
                default:
                  changeContent =
                    value.content_id &&
                    _value[index]?.content_id === value?.content_id;
                  if (!changeContent) _value[index] = value;
              }
            } else if (is_list) {
              //change toute la liste
              console.log("On change toute la liste " + prop.name ,{value})
              _value = value;
            } else if (prop.define) {
              _value = value;
            } else {
              console.log({_value ,    value})
              changeContent = _value?.content_id === value?.content_id;
              _value = value;
            }

            if (prop.define || !changeContent) {
              //On modifie la liste


              console.log("On met la liste dans le content ****",{ _value,setFunction,prop})
              let result = await setFunction.do({
                ...getFunction.do(),
                [prop.realName ?? prop.name]: _value,
              });
              return result;
            } else {
              // On modifie le contenu et pas le content_id
              console.log("_setContentObject *****" + prop.name, value);
              // on modifie directement le contenu
              let result = await setContentObject(value, prop.schema, contents);
              return result;
            }
          },
          stack: [
            ...setFunction.stack,
            " set " +
              prop.name +
              " object  from " +
              prop.from +
              " " +
              (prop.type_nombre === "I" || prop.type_nombre === "F"
                ? "[List]"
                : "") +
              (prop.schema.content.code === "numeric" ? " numeric " : ""),
          ],
        };
        

funcs["_open_" + prop.name] = {
  do: async (navigate,index,slug) => {
    //   if(!window.confirm("Save " +  prop.name +" (2) ??")) return
    var object = funcs["_get_" + prop.name].do(index)

    if (object?.content_id) {
      if(!slug)
        {
          // chercher pages trouver quelle est celle qui ouvre un objet avec 
           var result  = await retrieveProject().api("/get_data_slug/:app_id/:schema_id", {args:{app_id:infos.app_id, schema_id: prop.schema_id  }})
           slug = result?.slug
        }
          if(slug) 
            {
              slug= slug.split("/").map(s=> s.startsWith(":") ?  object?.content_id :s  ).join("/")
            }
           navigate(slug)
    }
  },
  stack: [
    ...setFunction.stack,
    " open " +
      prop.name +
      " object  from " +
      prop.from +
      " " +
      (prop.type_nombre === "I" || prop.type_nombre === "F"
        ? "[List]"
        : ""),
  ],
};


funcs["_popup_" + prop.name] = {
  do: async (index,template_id) => {
    //   if(!window.confirm("Save " +  prop.name +" (2) ??")) return
    var object = funcs["_get_" + prop.name].do(index)

    if (object?.content_id) {
   
          // chercher pages trouver quelle est celle qui ouvre un objet avec 
           var popup_template = await retrieveProject().api("/get_data_popup", {body:{template_id,app_id:infos.app_id, schema_id: prop.schema_id  }})
           // ouvrir une modale  

 
    }
  },
  stack: [
    ...setFunction.stack,
    " open " +
      prop.name +
      " object  from " +
      prop.from +
      " " +
      (prop.type_nombre === "I" || prop.type_nombre === "F"
        ? "[List]"
        : ""),
  ],
};
        funcs["_post_" + prop.name] = {
          do: async (value) => {
            //   if(!window.confirm("Save " +  prop.name +" (2) ??")) return
            if (value.content_id) {
              await postContentObject(value, prop.schema, contents);
            }
          },
          stack: [
            ...setFunction.stack,
            " post " +
              prop.name +
              " object  from " +
              prop.from +
              " " +
              (prop.type_nombre === "I" || prop.type_nombre === "F"
                ? "[List]"
                : ""),
          ],
        };
      }
    }
  }
  //console.log("Function getSetter for " + content_id,{contents, funcs } )

  computedProps.forEach((prop) => {
    funcs["_get_name_" + prop.name] = {
      do: () => {
        return prop.name;
      },
      stack: [...getSelf.stack, "get Name prop.name =" + prop.name],
    };

    funcs["_get_" + prop.name] = {
      do: (index, relativeContent) => {
        if (!infos) return false;

        //relativeContent permet de donner les fonctions get set par rapport à la hiérarcihie de la structure appelante
        var val = runActionSync(
          null,
          { ...prop.computed, self: infos.self },
          { ...infos.content, ...funcs, ...relativeContent },
          infos.setInternal,
          false
        );
        console.log(" EXECUTE _get_" + prop.name + " " + val);
        return val;
      },
      stack: [...getSelf.stack, "get compute   =" + prop.name],
    };

    let is_list = prop.type_nombre === "I" || prop.type_nombre === "F";

    if (is_list) {
      funcs["_get_count_" + prop.name] = {
        do: (relativeContent) => {
          var val = funcs["_get_" + prop.name].do(null, relativeContent);
          return Array.isArray(val) ? val.length : 0;
        },
        stack: [...getSelf.stack, "get Count prop.name =" + prop.name],
      };
    }
  });

if(infos?.dataSources){

  infos.dataSources.forEach(dataSource=>{ 

var prop = dataSource.prop

funcs["_load_" + prop.name] = {
  do: async() => {
   // On appelle la fonction data_source 
   var data = await getDataSource( dataSource.dataSource_id,funcs,dataSource.template_id ,dataSource.pageParams)
     console.log("_loal_ "+ prop.name  ,data)
   if(funcs["_set_" + prop.name]) await funcs["_set_" + prop.name].do(   data   )

  },
  stack: [ "load Source =" + prop.name],
};

/// Fonction à appeler une premiere fois pour charger 
funcs["_loading_" + prop.name] = {
  do: async(callBack) => {
   if(window.dataSource && window.dataSource[dataSource.id])  return;
   if(!window.dataSource)  window.dataSource ={}
   window.dataSource[dataSource.id]=true

   // On appelle la fonction data_source 
 await funcs["_load_" + prop.name].do()
 if(callBack) callBack()
  },
  stack: [ "Loading Source =" + prop.name],
};

    
 
}
  )


}

if(infos?.element_id)
  console.log("Getters Setters Functions for " + infos.element_id + " " + template.title , funcs);

  return funcs;
};

const getDataSources= async (page)=> {

var sources =[]
var dataSources ={} 
 
var ctxs = []

if (page.content.headerContent_id) ctxs.push({place:"header",content_id:page.content.headerContent_id})
  if (page.content.footerContent_id) ctxs.push({place:"footer",content_id:page.content.footerContent_id})
    page.content.body.forEach((body,i)=> {    if (body.content_id) ctxs.push({place:"body",index:i,content_id:body.content_id})    })
  
  for(let ctx of ctxs)
  {
    var  c =    await data.getContent( ctx.content_id,true,false)
  for (let key of    Object.keys(c.content)) {
     
  if(key.startsWith("_data_")) {
  
    if( !dataSources[  c.content[key].content_id] )dataSources[  c.content[key].content_id] = await  data.getContent(  c.content[key].content_id,true,false)
       sources.push({ title:c.title, i:ctx.index, schema_label: key.replace("_data_",""), place: ctx.place, schema_id :  dataSources[  c.content[key].content_id].content.schema.content_id   })
  }

  }
}

  
 return  sources;


}




export {
  getDataSources,
  loadAllContentAsync,
  _GetSetTers,
  getPropsFromTemplates,
  deleteClass,
  setPropInputs,
  updateStructure,
  adaptedContents,
  getStructure,
  FlatObject,
  RecomposeContent,
  arrayMerge,

  createClass,
  contentConvert,
  getConditions,
  loadCompleteContent,
  loadCompleteContentAsync,
  majContent,
  structureClean,
  structurePutIn,
  structureReplace,
  getProps,
  loadTemplate,
  activateContent,
  getPageTemplates,
  customLongHash,
  props_from_template,
  getIdStructure
};
