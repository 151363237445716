import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import * as fa from "@fortawesome/free-solid-svg-icons";
import { icon } from "@fortawesome/fontawesome-svg-core";
import useDynamicTextColor from "../../tools/useDynamicTextColor";
import InputText from "./InputText";
import { retrieveProject } from "../../service/mftc-api";
import ContentTemplate from "../display/ContentTemplate";

const defaultOptionRender = ({value,fieldSearch ,textColor,backgroundColor}) => <div style={{color:textColor,backgroundColor:backgroundColor}}>{ (typeof value ==="string") ? value : value[fieldSearch]}</div>
const SelectDropDown = ({ mainClassName, label, onChange, value, className,placeholder, options, optionsFilter, excludeValues, fieldSearch="title",fieldId, callClose,OptionRender=defaultOptionRender,dir,displayTemplateId}) => {
    const [showTab, setShowTab] = useState()
    const elementRef = useRef(null);
    const listRef = useRef(null);
    const [optionsValues, setOptionsValues] = useState(options ?? [])
    const {textColor,backgroundColor } = useDynamicTextColor(listRef);
const [reverse,setReverse]= useState(dir==="top")
    const [searchText, setSearchText] = useState()
    const [timer, setTimer] = useState(null);

    const textRef = useRef()


const onChangeDelay=(new_value)=>{

    if( timer) clearTimeout(timer);

    setTimer(setTimeout(() => {
      setTimer(null);
      onChange && setSearchText(new_value);
    }, 300));
}

const _setShoWTab=(_showTab)=>{
  setShowTab(_showTab)
  if(_showTab)
  {
    setSearchText()
    setTimeout(()=>{
      textRef.current?.focus()
    },100)
  }
}

const dropdownRef = useRef(null);

useEffect(() => {
    // Fonction pour gérer les clics en dehors du composant
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            _setShoWTab(false);
        }
    };

    // Ajouter l'écouteur d'événement lorsque le menu est ouvert
    if (showTab) {
        document.addEventListener('mousedown', handleClickOutside);
    }

    // Nettoyer l'écouteur d'événement
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [showTab]);

useEffect(()=>{

 const getOptions=async ()=>{
    if(showTab && optionsFilter) {
     var where,schema_id

     if(typeof optionsFilter === "string"){
        where = optionsFilter
     }else{
         where = optionsFilter.where?? "";
        schema_id = optionsFilter.schema_id;
     }
if(searchText) { 
where +=  (where?" AND ":"  ")+  ` ${fieldSearch} LIKE '%${searchText}%'`;


    }
    var _options = await retrieveProject().api("/get_content_where",{body : {schema_id, where}})
    setOptionsValues(_options)
}
 }
getOptions();

},[searchText,optionsFilter,showTab])
    return (
            <div className={mainClassName +" flex flex-col"} ref={dropdownRef}>    {
                displayTemplateId ?
                <div className="flex flex-row items-center w-full appbutton"><ContentTemplate
                className={" w-full"}
                template_id={displayTemplateId}
                 schema_id={optionsFilter.schema_id}
                content={typeof value === "object" ?value :null}
                content_id={typeof value === "string" || typeof value === "number" ?value :null}
                onClick={()=>_setShoWTab(!showTab)}
                />
                <FontAwesomeIcon icon={  showTab ? fa.faCaretUp : fa.faCaretDown} className="-ml-6 z-10 "  /></div>
                : 
                  <InputText 

        label={label}
        className={"w-full"}
        placeholder={placeholder}
     value={(value &&  value[fieldSearch]) ?? value}

         onFocus={()=>_setShoWTab(true)}

      />}

                   { showTab &&
                    <div className=" w-full flex flex-col justify-center items-center z-50 relative" >

      
                    
                        <div className={" w-full flex  justify-center items-start z-50 absolute left-0 " + ( reverse ? " flex-col-reverse bottom-0 ":" flex-col top-0") } >
                        <div   className="flex flex-row w-full  items-center justify-center">
                            <FontAwesomeIcon icon={fa.faSearch} className="-mr-8 z-10  " />
                            <input type='text' ref={textRef} onChange={(e) => onChangeDelay(e.target.value)} className="text-gray-500 bg-zinc-100 border border-zinc-800 w-full p-2  rounded-md pl-10" placeholder={"Choisir " +label}
                            />
                            <FontAwesomeIcon icon={fa.faClose} className="-ml-6 z-10 " onClick={() => { textRef.current.value = ''; setSearchText() ;_setShoWTab()}} />

                        </div>
                        
                        <div ref={listRef} style={{maxHeight:"400px"}} className="relative w-full h-1/2 bg-zinc-100 border border-zinc-800 shadow rounded-xl p-2 flex flex-col justify-start overflow-y-scroll " >
                            { [ ...(value? [value]:[]) ,...optionsValues.filter(option => {
                              if(value && ((value[fieldId] && value[fieldId]===option[fieldId]) || value+""===""+option[fieldId]) ) return false
                              if(optionsFilter) return (!excludeValues || !excludeValues.includes(option[fieldId])) 

                                   return  !searchText || option[fieldSearch].toUpperCase().includes(searchText.toUpperCase())
                            })].map((option, i) => <div  className={ className + (value && value[fieldId] && value[fieldId]===option[fieldId] ? " border border-blue-700 hover:border-red-600 hover:bg-red-600 ":" border border-transparent appbutton"     )}  key={i} onClick={() => { 
                                onChange((value && value[fieldId] && value[fieldId]===option[fieldId]) ? null: option); _setShoWTab() }} >
                          { 
                          displayTemplateId ?
                          <><ContentTemplate
                          template_id={displayTemplateId}
                           schema_id={optionsFilter.schema_id}
                           className={ className + (value && (( value[fieldId] && value[fieldId]===option[fieldId] ) || value+""===""+option[fieldId] ) ? " border border-blue-700 hover:border-red-600 hover:bg-red-600 ":" border-t appbutton"     )}
                        
                          content={typeof option === "object" ?option :null}
                          content_id={typeof option === "string" || typeof option === "number" ?option :null}
                          onClick={() => { 
                            onChange((value && value[fieldId] && value[fieldId]===option[fieldId]) ? null: option); _setShoWTab() }}
                       
                          />
                          </>
                          : 
                          <OptionRender value={option} fieldSearch={fieldSearch} textColor={textColor} backgroundColor={backgroundColor} /> 
                          }
                          </div>)}

                        </div>

                    </div></div> }</div>
    )
}
export default SelectDropDown
