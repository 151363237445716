import React, { useRef, useState } from "react";
import CodeEditor from '@uiw/react-textarea-code-editor';


const InputTextCodeEditor = ({id,label,onChange,value,className,bg="inherit",infos,language}) => {



  const [showBox, setShowBox] = useState(false);
  const [boxPosition, setBoxPosition] = useState({ top: 0, left: 0 });
  const editorRef = useRef(null);

  // Fonction pour gérer les changements de texte
  const handleChange = (event) => {
   // setCode(event.target.value);
    updateBoxPosition();
  };

  // Fonction pour gérer le focus ou le clic
  const handleFocus = (event) => {
    setShowBox(true);
    updateBoxPosition();
  };

  // Fonction pour mettre à jour la position de la boîte
  const updateBoxPosition = () => {
    var obj = document.activeElement ;   
    obj.classList.add("font-bold")
 //   window.alert(obj.innerHTML)
    if (editorRef.current) {
      const rect = editorRef.current.getBoundingClientRect();
      setBoxPosition({
        top: rect.bottom + window.scrollY,  // Juste en dessous de l'élément
        left: rect.left + window.scrollX,   // Aligné à gauche avec l'élément
      });
    }
  };


return (
    <>
    <div className={"relative bg-inherit " +className } >
     <div htmlFor={ "input_"+label } className={"text-left  text-gray-500 " +bg }>{label}</div>
     <div
      
       
        onChange={handleChange}
       
      className="relative"
      >
    <CodeEditor
   ref={editorRef}
      value={value}
      className="w-full rounded-md relative"
      language={language}
      onFocus={handleFocus}
      placeholder="Please enter JS code."
      onChange={(evn) =>{
        
        updateBoxPosition();
        onChange(evn.target.value);}}
      padding={15}
      style={{
        backgroundColor: "#050525",color :"white",caretColor: "white",
        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
      }}
    />
    </div>
    {showBox && (
        <div
          style={{
            position: "absolute",
            top: boxPosition.top,
            left: boxPosition.left,
            backgroundColor: "white",
            border: "1px solid black",
            padding: "10px",
            zIndex: 1000
          }}
        >
          Boîte d'information
        </div>
      )}
    {infos  &&  <div className="italic w-full text-xs p-1 text-left  whitespace-pre-line">{infos}</div>}
    </div>
    
</> 
)
}
export default InputTextCodeEditor